// MUI Icons
import EmailIcon from "@mui/icons-material/Email";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
// Images
import IMAGES from "./images";

const ABOUT = {
  title: "Who Are We?",
  subtitle: "Securing tomorrow & Beyond",
  content: [
    `Salem Ventures is a technology-first-driven asset management
                institution that provides reliable, experience-based consultancy
                to its wide book of clients.`,
    `Based in Cairo, Salem Ventures applies its expertise to serve
                investors and manage their wealth & Investment portfolios. Our
                customers mainly compromise Egyptian Expats living abroad and
                international investors seeking placement in Egypt.`,
  ],
};
const SECTORS = {
  title: "Sectors",
  subtitle: "Salem ventures excel while we focus on the following verticals.",
  sectorTaps: ["VENTURES", "agri-business", "Money markets", "Real estate"],
  sectorcontent: [
    {
      contenttitle: "Ventures",
      contentdesc:
        "Salem Ventures offers tailored investment solutions including consultancy and asset management, focusing on green energy technologies such as fintech, AI, agri-tech, IoT & blockchain. We create lasting solutions for clients.",
      img: IMAGES.SECTORS_MAIN,
    },
    {
      contenttitle: "Agri-business",
      contentdesc:
        "We specialize in agri-business, from investing and developing new farms to converting them to smart agriculture with IoT and solar energy. We create sustainable operations for lasting community impact and maximizing ROI.",
      img: IMAGES.SECTORS_MAIN_2,
    },
    {
      contenttitle: "Money Markets",
      contentdesc:
        "Salem Ventures offers consultancy on Money Markets, goal based planning & thematic baskets. Our SWealth fintech platform will digitally offer these services for easier investment manageability.",
      img: IMAGES.SECTORS_MAIN_3,
    },
    {
      contenttitle: "Real State",
      contentdesc:
        "Salem Ventures offers asset and project management services for global real estate investors. We specialize in operating existing and new assets, as well as fund investments to provide a complete service to our clients.",
      img: IMAGES.SECTORS_MAIN_4,
    },
  ],
};

const OUR_PROCESS = {
  title: "Our Process",
  subtitle: "The inner workings of financial security",
  backgroundImage: {
    backgroundImage: `url(${IMAGES.PROCESS_BACKGROUND_IMAGE})`,
    backgroundSize: "40%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
};

const OPPORTUNITIES = {
  title: "Open Opportunities",
};

const PARTNERS = {
  title: "Partners",
  subtitle: "The Story of Many, Realized.",
};

const BLOG = {
  title: "Our Blog",
  subtitle: "Keeping you informed on the latest.",
};

const FOOTER = {
  subtitle: `Providing Bespoke Asset Management Solutions to Accredited
              Investors & Families`,
  bottomText: "SALEM VENTURES | 2022 | All Rights Reserved",
  socialLinks: [
    {
      href: "https://www.facebook.com/SalemVenturesLLP",
      src: IMAGES.FACEBOOK_ICON,
    },
    {
      href: "https://twitter.com/salem_ventures_",
      src: IMAGES.TWITTER_ICON,
    },
    {
      href: "https://www.linkedin.com/company/salem-ventures/",
      src: IMAGES.LINKEDIN_ICON,
    },
    // {
    //   href: "https://www.youtube.com/channel/UCizEJiy_F1URrfum7yheCaQ",
    //   src: IMAGES.YOUTUBE_ICON,
    // },
    {
      href: "https://www.instagram.com/salemventures/",
      src: IMAGES.INSTAGRAM_ICON,
    },
  ],
  PRIVACY_POLICY: {
    copyRight: "SALEM VENTURES  |  2024  |  All Rights Reserved",
    privacy: "Privacy Policy | ",
    terms: "Terms & Conditions",
  },
  pagesCol: {
    title: "Links",
    links: [
      { name: "Home", link: "/#" },
      { name: "Agri-business", link: "/Agri-business/#" },
      { name: "About Us", link: "/about-us/#" },
      { name: "Real estate", link: "/#Real-estate/#" },
      { name: "Careers", link: "/Careers/#" },
      { name: "Ventures", link: "/Ventures/#" },
      { name: "Blog", link: "/Blog/#" },
      { name: "Money Markets", link: "/Money-Markets/#" },
    ],
  },
  contactCol: {
    title: "Contact",
    details: [
      {
        icon: <EmailIcon />,
        href: "mailto:info@salem.ventures",
        text: "info@salem.ventures",
      },
      {
        icon: <PhoneInTalkIcon />,
        href: "tel:+201200116677",
        text: "+20 (120) 011 66 77",
      },
      {
        icon: <FmdGoodIcon />,
        text: "Office 503, Reyhana Plaza Tower, Zahraa Maadi, Cairo, Egypt",
      },
    ],
  },
};

const CONTACT_INFO = {
  name: "Salem Ventures",
  address: "Office 503, Reyhana Plaza Tower, Zahraa Maadi, Cairo, Egypt",
  phone: "+201111313481",
};

const TERMS_CONTENT = {
  title: "Terms & Conditions",
  subtitle: "User agreement",
  icon: IMAGES.TERMS_TITLE_ICON_SVG,
  content: [
    `Introduction: Salem Ventures operates the website located at https://www.salemventures.co. This Terms and Conditions document outlines the rules and regulations for the use of Salem Ventures' website.`,
    `Agreement to Terms: By accessing this website, you agree to be bound by these Terms and Conditions, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.`,
    `Intellectual Property: The materials on Salem Ventures' website, including all content, logos, graphics, images, software, and other material are the property of Salem Ventures and are protected by international copyright and trademark laws. You may not use or reproduce any of these materials without the express written consent of Salem Ventures.`,
    `Restrictions on Use: You may not use Salem Ventures' website in any way that could cause damage to the site or impair the availability or accessibility of the site. You may not use Salem Ventures' website for any illegal or unauthorized purpose. You may not use Salem Ventures' website to engage in any activity that is harmful to the website, Salem Ventures, or its users.`,
    `Limitation of Liability: Salem Ventures shall not be liable for any damages arising from the use of this website. The materials on Salem Ventures' website are provided on an "as is" basis without warranty of any kind, either expressed or implied. Salem Ventures makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.`,
    `Changes to Terms and Conditions: Salem Ventures reserves the right to modify these Terms and Conditions at any time without notice. Your continued use of this site after any changes to these Terms and Conditions constitutes your acceptance of the new Terms and Conditions.`,
    `Governing Law: These Terms and Conditions are governed by and construed in accordance with the laws of the jurisdiction in which Salem Ventures is located, and you hereby consent to the exclusive jurisdiction of the courts in that jurisdiction.`,
    `Severability: If any provision of these Terms and Conditions is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.`,
    `Contact Information: If you have any questions or concerns regarding these Terms and Conditions, please contact us at info@salemventures.co`,
    `Date of Last Update: 1st of Jan 2024.`,
  ],
};

const PRIVACY_POLICY_CONTENT = {
  title: "Privacy Policy",
  subtitle: "Our personal statement, Cookies, Third-Parties",
  icon: IMAGES.PRIVACY_POLICY_TITLE_ICON_SVG,
  content: [
    {
      title: "Personal Statement",
      subtitle: `Salem Ventures is committed to protecting the privacy of its customers and website users. This Privacy Policy describes the type of information we collect, how it is used, shared, and protected. By using our website and services, you agree to the terms of this Privacy Policy.`,
      descriptionList: [],
    },
    {
      title: "Information Collection",
      subtitle:
        "We collect information from you when you visit our website, use our services, or communicate with us. This information may include, but is not limited to:",
      descriptionList: [
        `Personal information: such as your name, address, phone number, and email address.`,
        `Demographic information: such as your age, gender, and interests.`,
        `Technical information: such as your IP address, device type, and browser type.`,
        `Usage information: such as the pages you visit and how you interact with our website and services.`,
      ],
    },
    {
      title: "Information Use",
      subtitle: "We use the information we collect for the following purposes:",
      descriptionList: [
        `To provide and improve our website and services.`,
        `To communicate with you regarding our services, updates, and promotions.`,
        `To respond to your inquiries and support requests.`,
        `To analyze website usage and user behavior to improve our website and services.`,
      ],
    },
    {
      title: "Information Sharing",
      subtitle: `We do not sell or rent your personal information to third parties for their marketing purposes without your explicit consent. We may share your information with third parties for the following reasons:`,
      descriptionList: [
        `To provide you with the services you requested.`,
        `To comply with legal requirements, such as a court order or subpoena.`,
        `To enforce our terms of service and protect the rights, property, and safety of Salem Ventures and its users.`,
      ],
    },
    {
      title: "Information Protection",
      subtitle: `We take reasonable measures to protect your information from unauthorized access, use, disclosure, or destruction. We use encryption and firewalls to secure sensitive information and limit access to our systems to authorized personnel only.`,
      descriptionList: [],
    },
    {
      title: "Cookies and Tracking Technologies",
      subtitle: `Our website uses cookies and other tracking technologies to provide a better user experience and to analyze website usage and user behavior. A cookie is a small text file stored on your device that allows our website to recognize you and remember your preferences. You can control the use of cookies through your browser settings.`,
      descriptionList: [],
    },
    {
      title: "Changes to this Privacy Policy",
      subtitle: `We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. We will post the updated Privacy Policy on our website and notify you of any significant changes through a notice on our website or by email.`,
      descriptionList: [],
    },
    {
      title: "Contact Us",
      subtitle: `If you have any questions or concerns about our Privacy Policy, please contact us at info@salemventures.com.\nEffective Date: 1st of Jan 2024`,
      descriptionList: [],
    },
  ],
};

const CAREERS_CONTENT = {
  heroTitle: ["Grow Your Career", "\n", "With Us!"],
  teamTitle: "What Our Team says",
  teamMembers: [
    {
      name: "Lina Saied",
      job: "UI/UX Designer",
      content: [
        `Salem Ventures is a technology-first-driven asset management institution that provides reliable, experience-based consultancy to its wide book of clients.`,
        `Based in Cairo, Salem Ventures applies its expertise to serve investors and manage their wealth & Investment portfolios. Our customers mainly compromise Egyptian Expats living abroad and international investors seeking placement in Egypt.`,
      ],
      media: IMAGES.CAREERS_INTRO,
    },
    {
      name: "John Doe",
      job: "Software Engineer",
      content: [
        `Salem Ventures is a technology-first-driven asset management institution that provides reliable, experience-based consultancy to its wide book of clients.`,
        `Based in Cairo, Salem Ventures applies its expertise to serve investors and manage their wealth & Investment portfolios. Our customers mainly compromise Egyptian Expats living abroad and international investors seeking placement in Egypt.`,
      ],
      media: IMAGES.CAREERS_INTRO,
    },
  ],

  vacanciesTitle: "Current Openings",
  vacanciesSubTitle:
    "Explore our current job openings below and discover exciting opportunities to join our team",
  jobFilter: [
    "All",
    "Design",
    "Development",
    "Marketing",
    "Product",
    "Human Resources",
  ],
  jobTitles: [
    "Sr. DevOps",
    "Scrum Master",
    "Product/Business Analyst",
    "Tech Lead",
    "Full Stack ( Angular/ Node )",
    "Software Recruiter",
  ],
  vacancies: [
    {
      title: "Sr. DevOps",
      location: "Cairo",
      country: "Egypt",
      level: "mid",
      workLocation: "onsite",
      type: "full",
      category: "Development",

      content:
        "DevOps Engineers will play a critical role in ensuring the smooth deployment and operation of our software applications. As a DevOps Engineer, you will be responsible for automating,monitoring and optimizing the software development process, from development to production.",
      link: "#",
    },
    {
      title: "Scrum Master",
      category: "Product",

      location: "Cairo",
      country: "Egypt",
      level: "mid",
      workLocation: "onsite",
      type: "full",
      content:
        "We are seeking 2 highly motivated and experienced Scrum Masters to join our team. As a Scrum Master, you will be responsible for leading and managing Scrum teams, ensuring successful delivery of digital projects.",
      link: "#",
    },
    {
      title: "Product/Business Analyst",
      location: "Cairo",
      country: "Egypt",
      level: "junior",
      category: "Product",
      workLocation: "onsite",
      type: "part",
      content:
        "We are seeking a talented Product Analyst with a minimum of 3 years of experience to join our team at Salem Ventures! As a Product Analyst, you will be responsible for analyzing market and customer needs, defining product requirements, and collaborating with cross-functional teams to ensure successful product delivery.",
      link: "#",
    },
    {
      title: "Tech Lead",
      location: "Cairo",
      country: "Egypt",
      level: "mid",
      workLocation: "onsite",
      type: "part",
      category: "Development",
      content:
        "We are seeking a highly motivated and experienced Tech Lead to join our team. As a Tech Lead, you will be responsible for managing and delivering tech products from concept to launch and building and supervising an exceptional tech team. You will provide technical guidance and mentorship to team members.",
      link: "#",
    },
    {
      title: "Full Stack ( Angular/ Node ) ",
      location: "Cairo",
      country: "Egypt",
      level: "senior",
      workLocation: "onsite",
      type: "full",
      category: "Development",

      content:
        "We are looking for a Full Stack Developer with experience in Angular and Node.js to join our team in SV. In this role, you will be responsible for developing and maintaining both the front-end and back-end components of our web application. You should have a minimum of 3 years of experience in full-stack development with a focus on Angular and Node.js. You will work with our team of developers to ensure that our web application is fast, reliable, and scalable.",
      link: "#",
    },
    {
      title: "Software Recruiter",
      location: "Cairo",
      country: "Egypt",
      level: "mid",
      workLocation: "onsite",
      type: "full",
      category: "Human Resources",

      content:
        "Collaborate with hiring managers to understand job requirements and develop sourcing strategies Utilize LinkedIn and other online platforms to identify potential candidates who meet the specified criteria Conduct proactive searches and source candidates through various channels, including job boards, social media, and professional networks Review and screen resumes and applications to assess candidate qualifications and fit Build and maintain a strong network of potential candidates for future opportunities Collaborate with the recruiting team to continuously improve the sourcing and selection process",
      link: "#",
    },
  ],
  applyTitle: "Do you want to work for us?",
  applyBgImage: IMAGES.PROCESS_BACKGROUND_IMAGE,
};

// carrer page
const CAREERS_JOINSV = {
  title: "Join Salem Ventures",
  subTitle: "Embrace Innovation and Unlock Your Potential",
  desc: "At Salem Ventures, we offer an exceptional opportunity for talented individuals in the investment industry. Join our team to experience a dynamic work culture that fuels innovation, collaboration, and personal growth.",
  img: IMAGES.JOIN_SV,
};
const CAREERS_WHY_JOIN = {
  title: "Why join Salem Ventures?",
  subTitle:
    "At Salem Ventures, we offer an exceptional opportunity for talented individuals in the investment industry. Join our team to experience a dynamic work culture that fuels innovation, collaboration, and personal growth.",
  cards: [
    {
      title: "Innovative Environment",
      desc: " We thrive on innovation and encourage out-of-the-box thinking. Be part of a team that explores cutting-edge technologies and trends, delivering customized solutions to meet evolving client needs.",
      img: IMAGES.WHY_JOIN_1,
      bgImg: IMAGES.WHY_JOIN_BG_1,
    },
    {
      title: "Collaborative Mentorship",
      desc: "Collaborate with experienced professionals and industry experts who are passionate about mentoring and sharing knowledge. Benefit from open communication, idea-sharing, and constructive feedback to accelerate your development.",
      img: IMAGES.WHY_JOIN_2,
      bgImg: IMAGES.WHY_JOIN_BG_2,
    },
    {
      title: "Career Advancement",
      desc: "We invest in your growth. Unlock your potential through tailored training, industry certifications, and networking opportunities. We recognize and reward exceptional performance, ensuring your career reaches new heights.",
      img: IMAGES.WHY_JOIN_3,
      bgImg: IMAGES.WHY_JOIN_BG_3,
    },
    {
      title: "Meaningful Impact",
      desc: "Join us in shaping the investment landscape. Contribute to impactful projects, solve complex challenges, and make a difference in Egypt's investment future.",
      img: IMAGES.WHY_JOIN_4,
      bgImg: IMAGES.WHY_JOIN_BG_4,
    },
    {
      title: "Inclusive Community",
      desc: "Diversity is our strength. We foster an inclusive environment where everyone's unique perspective is valued. Embrace a supportive community that celebrates differences and encourages collaboration.",
      img: IMAGES.WHY_JOIN_5,
      bgImg: IMAGES.WHY_JOIN_BG_5,
    },
  ],
};
const BENEFITS = {
  title: "Benefits",
  subTitle:
    "At Salem Ventures, we invest in our employees just as we do in our clients. We offer a comprehensive benefits package designed to support our team members in Egypt. Enjoy the following perks.",
  cards: [
    {
      img: IMAGES.BENEFITS_ICON_1,
      title: "Health Insurance",
      desc: "Comprehensive health insurance coverage for you and your eligible dependents.",
    },
    {
      img: IMAGES.BENEFITS_ICON_2,
      title: "Pension Plan",
      desc: "Pension plan, corporate pensions, and ESOP packages for long-term financial security.",
    },
    {
      img: IMAGES.BENEFITS_ICON_3,
      title: "Flexible work",
      desc: "Flexible working hours and remote work options to foster work-life balance.",
    },
    {
      img: IMAGES.BENEFITS_ICON_4,
      title: "development opportunities",
      desc: "Professional development opportunities for personal and career growth.",
    },
    {
      img: IMAGES.BENEFITS_ICON_5,
      title: "Performance-based bonus",
      desc: "Performance-based bonus structure rewarding project over-delivery.",
    },
  ],
};
const OURTEAM = {
  title: "Our Team",
  cards: [
    {
      img: IMAGES.OUR_TEAM_1,
      title: "Eslam Mahmoud",
      job: "Front End Developer",
    },
    {
      img: IMAGES.OUR_TEAM_1,
      title: "Eslam Mahmoud",
      job: "Front End Developer",
    },
    {
      img: IMAGES.OUR_TEAM_1,
      title: "Eslam Mahmoud",
      job: "Front End Developer",
    },
    {
      img: IMAGES.OUR_TEAM_1,
      title: "Eslam Mahmoud",
      job: "Front End Developer",
    },
  ],
};
const SECTIONS_CONTENT = {
  ABOUT,
  SECTORS,
  OUR_PROCESS,
  OPPORTUNITIES,
  PARTNERS,
  BLOG,
  FOOTER,
  CONTACT_INFO,
  TERMS_CONTENT,
  PRIVACY_POLICY_CONTENT,
  CAREERS_CONTENT,
  CAREERS_JOINSV,
  CAREERS_WHY_JOIN,
  BENEFITS,
  OURTEAM,
};

export default SECTIONS_CONTENT;
