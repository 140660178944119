import { Box, Typography, styled } from "@mui/material";
import { COLORS } from "styles/theme";

export const PolicyContainer = styled(Box)(({ theme }) => ({
  minHeight: "615px",
  backgroundColor: COLORS.BLACK,
  paddingTop: theme.spacing(16),
  marginTop: theme.spacing(-8),
}));

export const PolicyTitle = styled(Typography)(() => ({
  color: COLORS.WHITE,
  position: "relative",
  zIndex: 20,
  maxWidth: "fit-content",
  "&::after": {
    content: '""',
    width: "50%",
    height: "11px",
    backgroundColor: COLORS.PRIMARY,
    position: "absolute",
    left: 0,
    bottom: "2px",
    zIndex: -1,
  },
}));

export default PolicyContainer;
