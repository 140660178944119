import { Container, Link, Stack, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IMAGES from "shared/images";
import { useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { HeroLink, HeroTitle, HeroSection } from "./hero.styles";

// TODO:
// Refactor to Styles
// Make it Responsive
// Use Link Custom component
function Hero() {
  const [mouseHover, setMouseHover] = useState(0);
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <HeroSection component="section">
      <Container sx={{ height: "100%" }}>
        <Stack
          sx={{
            position: { xs: "static", md: "absolute" },
            right: "8%",
            top: "25%",
            width: { xs: "100%", md: "31.25rem" },
            height: "100%",
            alignItems: "center",
          }}
        >
          <HeroTitle variant="h1">
            Venture <br />
            Into <br />
            Wealth
          </HeroTitle>
          <Typography sx={{ color: "#fff" }}>
            Providing World Class Financial Services
          </Typography>
          {/* <Stack
  sx={{
    pt: 8,
    flexDirection: "row",
    alignSelf: { xs: "center", md: "flex-start" },
    ml: { xs: "inherit", md: "5.43rem" },
    gap: 2,
    minWidth: 200,
  }}
  onMouseEnter={() => setMouseHover(1)}
  onMouseLeave={() => setMouseHover(0)}
>
  <HeroLink component={Link} href="#" isHovered={mouseHover}>
    Discover
    <Box
      sx={{
        pb: "4px",
        transition: "all .2s",
        position: "absolute",
        right: mouseHover ? "5px" : "15px",
        top: "12px",
      }}
    >
      <ArrowForwardIosIcon />
    </Box>
  </HeroLink>
</Stack> */}

          {matchesSM && (
            <Box
              component="img"
              src={IMAGES.SV_LOGO}
              sx={{
                marginTop: "auto",
                marginBottom: 4,
                width: "50%",
                minWidth: 220,
              }}
            />
          )}
        </Stack>
      </Container>
    </HeroSection>
  );
}

export default Hero;
