//
import { HashLink } from "react-router-hash-link";
import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";

// Shared
import IMAGES from "shared/images";
import SECTIONS_CONTENT from "shared/sections";
// Styles
import {
  FooterColumnTitle,
  FooterSection,
  FooterText,
  FooterTextLink,
} from "./footer.styles";

// TODO:
// Make it Responsive

function getContactDetails(contactList) {
  return contactList.map((row, index) => {
    const key = `footer-contact-${index}`;
    return (
      <Stack key={key} gap={1} direction="row">
        {row.icon}
        <FooterText
          sx={{ color: "#fff", fontWeight: 500 }}
          component={Link}
          href={row.href}
        >
          {row.text}
        </FooterText>
      </Stack>
    );
  });
}

function getPagesDetails(pages) {
  return pages.map((page) => {
    return (
      <Grid item key={`footer-link-${page.name}`} md={6} xs={12}>
        <FooterTextLink component={HashLink} to={page.link}>
          {page.name}
        </FooterTextLink>
      </Grid>
    );
  });
}

function getSocialLinks(socialLinks) {
  return socialLinks.map((link, index) => {
    const key = `social-icon-${index}`;
    return (
      <IconButton component={Link} href={link.href} key={key} target="_blank">
        <Box component="img" src={link.src} />
      </IconButton>
    );
  });
}

function Footer() {
  const { FOOTER } = SECTIONS_CONTENT;
  return (
    <FooterSection component="footer">
      <Container>
        <Grid container rowGap={4} columnSpacing={2}>
          <Grid
            item
            md={5}
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Box
              component="img"
              src={IMAGES.SV_LOGO}
              sx={{ maxWidth: "12.5rem" }}
            />
            <Typography
              sx={{ fontFamily: "inter", textAlign: "left", maxWidth: 300 }}
            >
              {FOOTER.subtitle}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                paddingTop: 4,
              }}
            >
              {getSocialLinks(FOOTER.socialLinks)}
            </Box>
          </Grid>
          <Grid item md={4} sx={{ textAlign: "left" }}>
            <FooterColumnTitle>{FOOTER.pagesCol.title}</FooterColumnTitle>
            <Grid container sx={{ rowGap: 2, paddingTop: 4 }}>
              {getPagesDetails(FOOTER.pagesCol.links)}
            </Grid>
          </Grid>
          <Grid item md={3} sx={{ textAlign: "left" }}>
            <FooterColumnTitle>{FOOTER.contactCol.title}</FooterColumnTitle>
            <Stack sx={{ paddingTop: 4, gap: 2 }}>
              {getContactDetails(FOOTER.contactCol.details)}
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Divider />
      <Container>
        <Box
          sx={{ paddingTop: 4, borderTop: "0.5px solid #585858", mt: 2 }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <FooterText>{FOOTER.PRIVACY_POLICY.copyRight}</FooterText>
          <Box>
            <FooterTextLink
              sx={{ fontWeight: 400 }}
              component={HashLink}
              to="/privacy-policy/#"
            >
              {FOOTER.PRIVACY_POLICY.privacy}
            </FooterTextLink>
            <FooterTextLink
              sx={{ fontWeight: 400 }}
              component={HashLink}
              to="/terms-conditions/#"
            >
              {FOOTER.PRIVACY_POLICY.terms}
            </FooterTextLink>
          </Box>
        </Box>
      </Container>
    </FooterSection>
  );
}

export default Footer;
