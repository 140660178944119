import React from "react";
import { Container, Grid } from "@mui/material";
import SECTIONS_CONTENT from "shared/sections";
import {
  JoinSVContainer,
  JoinSVContainerImg,
  JoinSVContent,
  JoinSVContentSubDesc,
  JoinSVContentSubTitle,
  JoinSVContentTitle,
} from "./JoinSV.style";

function JoinSV() {
  const { CAREERS_JOINSV } = SECTIONS_CONTENT;
  return (
    <JoinSVContainer>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6} display="flex" justifyContent="center">
          <JoinSVContainerImg src={CAREERS_JOINSV.img.src} />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <JoinSVContent>
            <JoinSVContentTitle variant="h3">
              {CAREERS_JOINSV.title}
            </JoinSVContentTitle>
            <JoinSVContentSubTitle variant="subtitle1">
              {CAREERS_JOINSV.subTitle}
            </JoinSVContentSubTitle>
            <JoinSVContentSubDesc varianr="body1">
              {CAREERS_JOINSV.desc}
            </JoinSVContentSubDesc>
          </JoinSVContent>
        </Grid>
      </Grid>
    </JoinSVContainer>
  );
}

export default JoinSV;
