import { Stack, Typography, styled } from "@mui/material";
import IMAGES from "shared/images";
import { COLORS } from "styles/theme";

export const HeroSection = styled(Stack)(({ theme }) => ({
  minHeight: "615px",
  backgroundImage: `linear-gradient(252.38deg, rgba(17, 17, 17, 0.11) 0%, #111111 100%), url(${IMAGES.ABOUT_HERO_BG.src})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  paddingTop: theme.spacing(8),
  marginTop: theme.spacing(-8),
  justifyContent: "center",
}));

export const HeroTitle = styled(Typography)(() => ({
  fontWeight: 700,
  color: COLORS.WHITE,
}));

export const AboutContentImage = styled(Stack)(() => ({
  maxWidth: 480,
  maxHeight: 480,
  width: "100%",
  heigth: "100%",
  objectFit: "contain",
  margin: "auto",
}));
