import { Fragment } from "react";

import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { COLORS } from "styles/theme";
import SECTIONS_CONTENT from "shared/sections";

import { PolicyContainer, PolicyTitle } from "./policyContent.styles";

function getPolicyDescriptionContent(descriptionList, policyTitle) {
  return descriptionList.map((content, index) => {
    const key = `${policyTitle}-description-${index}`;
    return (
      <Typography
        key={key}
        variant="body1"
        component="li"
        sx={{
          color: COLORS.WHITE,
          listStyle: "inside",
        }}
        paragraph
      >
        {content}
      </Typography>
    );
  });
}

function getPolicyContent(contentList) {
  return contentList.map((content, index) => {
    const key = `policy-content-${index}`;
    const { title, subtitle, descriptionList } = content;
    return (
      <Fragment key={key}>
        <Grid item xs={12} md={5}>
          <PolicyTitle variant="h5">{title}</PolicyTitle>
        </Grid>
        <Grid item xs={12} md={7}>
          <Typography
            variant="body1"
            sx={{ color: COLORS.WHITE, whiteSpace: "pre-wrap" }}
            paragraph
          >
            {subtitle}
          </Typography>
          <Box component="ul">
            {getPolicyDescriptionContent(descriptionList, title)}
          </Box>
        </Grid>
      </Fragment>
    );
  });
}

function PolicyContent() {
  const { PRIVACY_POLICY_CONTENT } = SECTIONS_CONTENT;
  return (
    <PolicyContainer component="section">
      <Container>
        <Stack direction="row" gap={2}>
          <Box component={PRIVACY_POLICY_CONTENT.icon} />
          <Box>
            <Typography
              variant="h2"
              sx={{ color: COLORS.WHITE, fontWeight: 700 }}
            >
              {PRIVACY_POLICY_CONTENT.title}
            </Typography>
            <Typography
              variant="h5"
              sx={{ color: COLORS.PRIMARY, fontWeight: 700 }}
            >
              {PRIVACY_POLICY_CONTENT.subtitle}
            </Typography>
          </Box>
        </Stack>
        <Grid container sx={{ paddingY: 4 }} rowGap={4}>
          {getPolicyContent(PRIVACY_POLICY_CONTENT.content)}
        </Grid>
      </Container>
    </PolicyContainer>
  );
}
export default PolicyContent;
