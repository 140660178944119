import PropTypes from "prop-types";

import { ContactFullPage, ContactPopup } from "./contact.variants";

function Contact({ variant }) {
  if (variant === "popup") {
    return <ContactPopup />;
  }
  return <ContactFullPage />;
}

Contact.propTypes = {
  variant: PropTypes.string,
};

Contact.defaultProps = {
  variant: "",
};
export default Contact;
