import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Container, Grid, Stack } from "@mui/material";
import sections from "shared/sections";
import {
  SectorContainer,
  SectorContainerImg,
  SectorContentContainer,
  SectorContentContent,
  SectorContentTitle,
  SectorImg,
  SectorSide,
  StyledTab,
  StyledTabs,
} from "./sectorComp.style";

const { SECTORS } = sections;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3, minHeight: "478px" }}>{children}</Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  //   children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SectorsComp() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Container>
        <Box>
          <StyledTabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="basic tabs example"
          >
            {SECTORS?.sectorTaps?.map((tap, index) => (
              <StyledTab label={tap} {...a11yProps(index)} key={tap} />
            ))}
            {/* <StyledTab label="Item Two" {...a11yProps(1)} />
            <StyledTab label="Item Three" {...a11yProps(2)} />
            <StyledTab label="Item Three" {...a11yProps(3)} /> */}
          </StyledTabs>
        </Box>
        {SECTORS?.sectorcontent?.map((item, index) => (
          <TabPanel value={value} index={index} key={item.contenttitle}>
            <SectorContainer>
              <SectorSide />
              <Grid container rowGap={2} sx={{ height: "100%" }}>
                <Grid item md={6}>
                  <Stack justifyContent="center" height="100%">
                    <SectorContainerImg>
                      <SectorImg src={item.img} />
                    </SectorContainerImg>
                  </Stack>
                </Grid>
                <Grid item md={6}>
                  <Stack
                    //   alignItems="center"
                    justifyContent="center"
                    height="100%"
                  >
                    <SectorContentContainer>
                      <SectorContentTitle>
                        {item.contenttitle}
                      </SectorContentTitle>
                      <SectorContentContent>
                        {item.contentdesc}
                      </SectorContentContent>
                    </SectorContentContainer>
                  </Stack>
                </Grid>
              </Grid>
            </SectorContainer>
          </TabPanel>
        ))}
      </Container>
    </Box>
  );
}
export default SectorsComp;
