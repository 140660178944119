import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import Link from "components/Link/link.component";
import { COLORS } from "styles/theme";
import IMAGES from "shared/images";

import {
  AboutContentImage,
  HeroSection,
  HeroTitle,
} from "./aboutContent.styles";

const AboutUsData = {
  title: "Company History",
  description: `Salem Ventures was founded in 2022 with the goal of creating a new standard for investment management. Our team of experienced professionals has a track record of success in various industries, including agriculture, real estate, venture capital, and money markets. We strive to provide our clients with the highest level of service and returns on investment.`,
  media: IMAGES.ABOUT_COMPANY_HISTORY,
  footerText: "Discover More Products & Features",
  footerLink: "/about-us/#",
  footerLinkText: "Discover",
  content: [
    {
      title: "Mission and Vision",
      description:
        "Our mission is to create long-term value for our clients by providing access to diverse investment opportunities and expert guidance. Our vision is to be a leader in the investment management industry, known for our integrity, innovation, and excellence.",
      media: IMAGES.ABOUT_MISSION_VISION,
    },
    {
      title: "Management Team",
      description:
        "Our management team is composed of seasoned professionals with a wealth of experience in various industries. We have diverse skills and expertise that allow the best possible service to our clients. Our team includes experts in finance, accounting, law, economics, and other related fields.",
      media: IMAGES.ABOUT_MANAGEMENT_TEAM,
    },
    {
      title: "Company Culture",
      description:
        "At Salem Ventures, we believe in creating a culture of excellence and integrity. We strive to foster a positive and collaborative work environment where all employees can grow and succeed. We are committed to promoting a culture of accountability, transparency, and continuous improvement.",
      media: IMAGES.ABOUT_COMPANY_CULTURE,
    },
    {
      title: "Community Involvement",
      description:
        "Salem Ventures is committed to giving back to the community. We support various charitable organizations and causes, and encourage our employees to volunteer their time and talents to make a positive impact in the community.",
      media: IMAGES.ABOUT_COMMUNITY_INVOLVEMENT,
    },
    {
      title: "Partnerships",
      description:
        "Salem Ventures has established partnerships with a number of leading firms in the investment management industry. These partnerships allow us to provide our clients with access to a wider range of investment opportunities and expert guidance.",
      media: IMAGES.ABOUT_PARTNERSHIPS,
    },
  ],
};

function getAboutContent(contentList) {
  return contentList.map((content, index) => (
    <Grid
      container
      key={content.title}
      direction={index % 2 === 0 ? "row" : "row-reverse"}
      spacing={4}
    >
      <Grid item xs={12} md={7}>
        <Stack gap={12} sx={{ height: "100%" }}>
          <Typography variant="h2">{content.title}</Typography>
          <Typography variant="body1" sx={{ lineHeight: 2.5 }}>
            {content.description}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} md={5}>
        <AboutContentImage
          component="img"
          src={content.media.src}
          alt={content.media.alt}
        />
      </Grid>
    </Grid>
  ));
}

function AboutContent() {
  const { ABOUT_DOUBLE_QUOTES_SVG } = IMAGES;
  return (
    <>
      <HeroSection component="section">
        <Container>
          <HeroTitle variant="h1">
            Our <br />
            History
          </HeroTitle>
        </Container>
      </HeroSection>
      <Container
        component="section"
        sx={{
          paddingY: 8,
        }}
      >
        <Stack gap={8}>
          <Typography variant="h2" textAlign="center" sx={{ marginBottom: 4 }}>
            {AboutUsData.title}
          </Typography>
          <Grid container spacing={4}>
            <Grid xs={12} md={6} item>
              <AboutContentImage
                component="img"
                src={AboutUsData.media.src}
                alt={AboutUsData.media.alt}
              />
            </Grid>
            <Grid xs={12} md={6} item sx={{ position: "relative" }}>
              <Box
                component={ABOUT_DOUBLE_QUOTES_SVG}
                sx={{ position: "absolute", top: 0, left: 0 }}
              />
              <Typography variant="body1" sx={{ lineHeight: 2.2 }}>
                {AboutUsData.description}
              </Typography>
            </Grid>
          </Grid>
          {getAboutContent(AboutUsData.content)}
          <Grid
            container
            spacing={4}
            sx={{ justifyContent: { xs: "center", md: "flex-end" } }}
          >
            <Grid item xs={12} md={9}>
              <Typography variant="h3">{AboutUsData.footerText}</Typography>
            </Grid>
            <Grid item xs={5} md={3}>
              <Link
                href={AboutUsData.footerLink}
                text={AboutUsData.footerLinkText}
                linkStyles={{ color: COLORS.BLACK }}
                containerStyles={{ padding: 0, minWidth: "200px" }}
              />
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </>
  );
}
export default AboutContent;
