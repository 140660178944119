import axios from "axios";
import { useState, useMemo, useEffect } from "react";

const featuredPostID = 82;
async function getPosts() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_WORDPRESS_BLOG_URL}/posts/?order=desc&orderby=date&per_page=3&exclude[0]=${featuredPostID}`,
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to load posts");
  }
}

function usePosts() {
  const [posts, setPosts] = useState([]);
  const postsList = useMemo(() => posts, [posts]);

  useEffect(() => {
    getPosts().then((posts) => setPosts(posts));
  }, []);
  return postsList;
}

async function getFeaturedPost() {
  const response = await axios.get(
    `${process.env.REACT_APP_WORDPRESS_BLOG_URL}/posts?include[]=${featuredPostID}`,
  );
  return response.data[0];
}

export function useFeaturedPost() {
  const [featuredPost, setFeaturedPost] = useState({});
  const post = useMemo(() => featuredPost, [featuredPost]);

  useEffect(() => {
    getFeaturedPost().then((post) => setFeaturedPost(post));
  }, []);

  return post;
}

export default usePosts;
