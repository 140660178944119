import PropTypes from "prop-types";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import SECTIONS_CONTENT from "shared/sections";

import { COLORS } from "styles/theme";
import { useEffect, useState } from "react";
import { AccordionStyle } from "./vacancies.style";
import Modal from "./Modal/Modal.component";

function Vacancies(props) {
  const { vacancies } = props;
  const { CAREERS_CONTENT } = SECTIONS_CONTENT;
  const [activeButton, setActiveButton] = useState("All");
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [filterJob, setFilterJob] = useState([]);
  useEffect(() => {
    setFilterJob(vacancies);
  }, []);

  const handleActiveClick = (jobTitle) => {
    setActiveButton(jobTitle);
    if (jobTitle === "All") {
      setFilterJob(vacancies);
    } else {
      const filter = vacancies?.filter((job) => job.category === jobTitle);
      setFilterJob(filter);
    }
  };
  const jobs = CAREERS_CONTENT?.jobFilter?.map((job) => (
    <Typography
      key={job}
      variant="h5"
      textAlign="center"
      sx={{
        color: activeButton === job ? COLORS.WHITE : COLORS.TEXT_GREY,
        fontSize: "1rem",
        backgroundColor: activeButton === job && "#066CFE",
        padding: "12px 24px",
        marginRight: 3,
        cursor: "pointer",
      }}
      onClick={() => handleActiveClick(job)}
    >
      {job}
    </Typography>
  ));
  function getCareersVacancies(vacancies, matchesXS) {
    return vacancies.length ? (
      vacancies.map((vacancy) => {
        return (
          <Grid item key={`${vacancy.title} - ${vacancy.level}`} md={6} xs={12}>
            <AccordionStyle>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Stack gap={4} sx={{ padding: 2, width: "100%" }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      variant="h5"
                      sx={{ fontSize: "1rem" }}
                      textTransform="capitalize"
                    >
                      {vacancy.title}
                    </Typography>
                    <ExpandMoreIcon />
                  </Stack>
                  <Divider />
                  <Stack
                    gap={2}
                    sx={{ flexDirection: { xs: "column", sm: "row" } }}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Stack direction="row" gap={1}>
                      <LocationOnIcon color="primary" />
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "#4F4F4F",
                        }}
                      >
                        {vacancy.location} - {vacancy.country}
                      </Typography>
                    </Stack>

                    <Divider
                      orientation={matchesXS ? "horizontal" : "vertical"}
                    />

                    <Typography
                      variant="subtitle1"
                      textTransform="capitalize"
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "#333333",
                        lineHeight: 2.5,
                      }}
                    >
                      {vacancy.workLocation}
                    </Typography>
                    <Divider
                      orientation={matchesXS ? "horizontal" : "vertical"}
                    />
                    <Typography
                      variant="subtitle1"
                      textTransform="capitalize"
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "#333333",
                      }}
                    >
                      {vacancy.type} - Time
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        fontWeight: 700,
                        fontSize: "16px",
                        fontFamily: "Montserrat",
                        minWidth: "160px",
                      }}
                      components={Link}
                      href={`mailto:careers@salem.ventures?subject=Applying to ${vacancy.title} Position`}
                    >
                      Apply Now
                    </Button>
                  </Stack>
                  {/* <Divider /> */}
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ fontWeight: 400 }}>
                  {vacancy.content}
                </Typography>
              </AccordionDetails>
            </AccordionStyle>
          </Grid>
        );
      })
    ) : (
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          textAlign="center"
          variant="subtitle1"
          sx={{ fontWeight: 400, fontSize: "24px" }}
        >
          No jobs available Stay tuned
        </Typography>
      </Grid>
    );
  }
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Box
      component="section"
      sx={{ marginY: 4, minHeight: 700, position: "relative" }}
    >
      {/* <VerticalBar /> */}
      <Container>
        <Typography
          variant="h3"
          textAlign="center"
          sx={{ marginBottom: 1, color: "#404040" }}
        >
          {CAREERS_CONTENT.vacanciesTitle}
        </Typography>
        <Typography
          variant="body1"
          textAlign="center"
          sx={{
            color: COLORS.TEXT_GREY,
            fontWeight: 400,
            width: "85%",
            margin: "auto",
            marginBottom: 8,
          }}
        >
          {CAREERS_CONTENT.vacanciesSubTitle}
        </Typography>
        <Box mb={4} display="flex" justifyContent="center">
          <Stack
            width="85%"
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {jobs}
          </Stack>
        </Box>
        <Grid container spacing={2} justifyContent="center">
          {getCareersVacancies(filterJob, matchesXS)}
        </Grid>
      </Container>
    </Box>
  );
}

Vacancies.propTypes = {
  vacancies: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
};

export default Vacancies;
