import styled from "@emotion/styled";
import {
  Box,
  DialogTitle,
  Typography,
  DialogContent,
  Dialog,
  TextField,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { COLORS } from "styles/theme";
import IMAGES from "shared/images";

export const ModalDesc = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: "#595959",
}));
export const MarkIcon = styled(FiberManualRecordIcon)(({ theme }) => ({
  color: "#595959",
  fontSize: ".5rem",
}));

export const ModalDialog = styled(Dialog)(({ theme }) => ({
  height: "95%",

  "& .css-rudpty-MuiPaper-root-MuiDialog-paper": {
    maxWidth: "850px",
    maxHeight: "100%",
    paddingTop: "20px",
    top: "20px",
    paddingBottom: "21px",
    borderRadius: "16px",
  },
}));
export const ModalForm = styled("form")(({ theme }) => ({
  backgroundImage: `url(${IMAGES.CAREER_POPUP_FORM.src})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
}));
export const ModalDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontWeight: 700,
  paddingLeft: 0,
  fontSize: "24px",
  fontFamily: "Tomato Grotesk",
  color: COLORS.PRIMARY,
}));
export const ModalDialogContent = styled(DialogContent)(({ theme }) => ({
  border: "none",
  paddingTop: 0,
}));
export const ModalNormalText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "14px",
  color: COLORS.GREY_DARK,

  paddingTop: "0 ",
  paddingBottom: "0",
  paddingRight: "24px",
  "& span": {
    fontWeight: 800,
    textDecoration: "underline",
  },
}));
export const ModalTitleDesc = styled(Typography)(({ theme }) => ({
  fontWeight: 800,
  color: "#000",
}));
export const ModalContentImg = styled("img")(({ theme }) => ({
  objectFit: "contain",
  height: "26px",
  width: "26px",
}));
export const ModalInput = styled(TextField)(({ theme }) => ({
  padding: theme.spacing(2),
  fontFamily: "Montserrat",
  fontWeight: 400,

  fontSize: "16rem",
  ":focus": {
    border: "1px solid #E7E3E3",
    outline: "none",
  },
}));
