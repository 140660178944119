import IMAGES from "shared/images";
import { COLORS, GRADIENTS, SHADOWS } from "styles/theme";
import { Box, Container, Typography, styled } from "@mui/material";

export const JoinSVContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(12),
  marginBottom: theme.spacing(4),
}));
export const JoinSVContainerImg = styled("img")(({ theme }) => ({
  maxWidth: "525px",
  maxHeight: "377px",
  width: "100%",
  objectFit: "contain",
}));
export const JoinSVContent = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    // paddingRight: theme.spacing(12),
  },
  [theme.breakpoints.down("md")]: {
    // paddingLeft: theme.spacing(4),
  },
}));
export const JoinSVContentTitle = styled(Typography)(({ theme }) => ({
  fontSize: "3.2rem",
  color: "#066CFE",
}));
export const JoinSVContentSubTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Montserrat",
  fontSize: "1.5rem",
  fontWeight: 400,
  [theme.breakpoints.up("lg")]: {
    paddingRight: theme.spacing(10),
  },
  marginBottom: theme.spacing(2),
}));
export const JoinSVContentSubDesc = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: COLORS.TEXT_GREY,
  lineHeight: "24px",
  paddingRight: theme.spacing(4),
}));
