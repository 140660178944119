import { Link, Typography, styled, Box, Button } from "@mui/material";
import { COLORS, GRADIENTS } from "styles/theme";
import IMAGES from "shared/images";

export const HeroSection = styled(Box)(({ theme }) => ({
  height: "815px",
  backgroundImage: `linear-gradient(252.38deg, rgba(17, 17, 17, 0.11) 0%, #111111 100%), url(${IMAGES.HERO_BACKGROUND_IMAGE})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  paddingTop: theme.spacing(8),
  marginTop: theme.spacing(-8),
  position: "relative",
  [theme.breakpoints.down("md")]: {
    paddingTop: theme.spacing(16),
    backgroundImage: `linear-gradient(252.38deg, rgba(17, 17, 17, 0.11) 0%, #111111 100%), url(${IMAGES.HOME_HERO_BG_SMALL_IMAGE})`,
  },
}));

export const HeroTitle = styled(Typography)(({ theme }) => ({
  fontSize: "80px",
  lineHeight: "72px",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundImage: GRADIENTS.HERO_TITLE,
  position: "relative",
  marginBottom: theme.spacing(8),
  "&::after": {
    content: '""',
    position: "absolute",
    width: "100%",
    maxWidth: "280px",
    height: "7px",
    backgroundColor: COLORS.GREEN,
    bottom: -20,
    left: 0,
  },
}));

export const HeroLink = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isHovered",
})(({ isHovered }) => ({
  position: "relative",
  width: "168px",
  height: "50px",
  color: "white",
  fontFamily: "Montserrat",
  fontSize: "18px",
  fontWeight: 700,
  zIndex: 5,
  backgroundColor: "#066CFE",
  "&:hover": {
    backgroundColor: "#066CFE",
  },
  "&::after": {
    content: '""',
    width: "15%",
    position: "absolute",
    color: "red",
    right: "12px",
    top: "23px",
    zIndex: -1,
    height: "2px",
    transition: "all .3s",
    background: "#fff",
    // display: isHovered ? "block" : "none",
    opacity: isHovered ? 1 : 0,
    visualViewport: isHovered ? "visualViewport" : "hidden",
  },
}));
