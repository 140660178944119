import { Box, Container, Stack, Typography } from "@mui/material";
import { COLORS } from "styles/theme";
import SECTIONS_CONTENT from "shared/sections";

import { TermsContainer } from "./termsContent.styles";

function getTermsContent(contentList) {
  return contentList.map((content, index) => {
    const key = `terms-content${index}`;
    return (
      <Typography
        key={key}
        variant="body1"
        sx={{ color: COLORS.WHITE }}
        paragraph
      >
        {content}
      </Typography>
    );
  });
}

function TermsContent() {
  const { TERMS_CONTENT } = SECTIONS_CONTENT;
  return (
    <TermsContainer component="section">
      <Container>
        <Stack direction="row" gap={2}>
          <Box component={TERMS_CONTENT.icon} />
          <Box>
            <Typography
              variant="h2"
              sx={{ color: COLORS.WHITE, fontWeight: 700 }}
            >
              {TERMS_CONTENT.title}
            </Typography>
            <Typography
              variant="h5"
              sx={{ color: COLORS.PRIMARY, fontWeight: 700 }}
            >
              {TERMS_CONTENT.subitle}
            </Typography>
          </Box>
        </Stack>
        <Stack sx={{ paddingY: 4 }}>
          {getTermsContent(TERMS_CONTENT.content)}
        </Stack>
      </Container>
    </TermsContainer>
  );
}
export default TermsContent;
