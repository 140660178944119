import { Accordion, Box, styled } from "@mui/material";
import { COLORS } from "styles/theme";

export const AccordionStyle = styled(Accordion)(() => ({
  position: "relative",
  boxShadow:
    "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
  "&:after": {
    content: `""`,
    backgroundColor: "#0AC70F",
    position: "absolute",
    width: "8px",
    height: "100%",
    left: 0,
    top: 0,
  },
}));
export const h = styled(Box)(() => ({
  maxWidth: 350,
  width: "100%",
  "& .MuiOutlinedInput-root": {
    backgroundColor: COLORS.WHITE,
    boxSizing: "inherit",
  },
}));
