import { Box, Typography, styled } from "@mui/material";
import { COLORS } from "styles/theme";
import IMAGES from "shared/images";

//  Popup
export const ContactUSContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  //   height: "643px",
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
  backgroundImage: `url(${IMAGES.CONTACT_BG_IMAGE})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
}));
export const ContactTitle = styled(Typography)(() => ({
  fontSize: "3.125rem",
  maxWidth: "373px",
  textAlign: " center",
  padding: "23px 20px",
  background: "#1E1E1E",
  color: COLORS.WHITE,
}));
export const ContactContent = styled(Typography)(({ theme }) => ({
  color: COLORS.WHITE,
  marginTop: theme.spacing(7),
}));

export const ContactInput = styled("input")(({ theme }) => ({
  padding: theme.spacing(2),
  fontFamily: "inherit",
  ":focus": {
    border: "1px solid #E7E3E3",
    outline: "none",
  },
}));
export const ContactTextArea = styled("textarea")(({ theme }) => ({
  padding: theme.spacing(2),
  fontFamily: "inherit",
  resize: "none",
  ":focus": {
    border: "1px solid #E7E3E3",
    outline: "none",
  },
}));
