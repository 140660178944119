// MUI
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// Theme
import { COLORS } from "styles/theme";

//  Popup
export const ContactPopupContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60rem",
  height: "40rem",
  backgroundColor: COLORS.BLACK,
  padding: theme.spacing(4),
}));

export const PopupTitleBox = styled(Stack)(() => ({
  position: "absolute",
  left: "10%",
  top: "2%",
  width: "22rem",
  height: "6rem",
  backgroundColor: COLORS.BLACK_SECONDARY,
  zIndex: 100,
  alignItems: "center",
  justifyContent: "center",
}));

export const ContactTitle = styled(Typography)(() => ({
  fontFamily: "Tomato Grotesk",
  fontWeight: 700,
  fontSize: "3rem",
  color: COLORS.WHITE,
}));
export const BorderBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "55rem",
  minHeight: "35rem",
  backgroundColor: "transparent",
  paddingTop: theme.spacing(12),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  [theme.breakpoints.down("md")]: {
    width: "100%",
    position: "static",
    transform: "initial",
    padding: theme.spacing(12, 0),
  },
}));

export const BgLines = styled(Box, {
  shouldForwardProp: (prop) => prop !== "alignLines",
})(({ alignLines }) => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  left: 0,
  top: 0,
  zIndex: -1,
  "&::before": {
    content: '""',
    position: "absolute",
    ...(alignLines === "right" ? { right: 0 } : { left: 0 }),
    top: 0,
    height: "100%",
    width: "2px",
    background:
      alignLines === "right"
        ? "linear-gradient(218.55deg,rgba(10, 199, 15, 0.38)   22.17%,rgba(5, 107, 255, 0.55) 83.48%)"
        : "linear-gradient(218.55deg, rgba(5, 107, 255, 0.55) 22.17%, rgba(10, 199, 15, 0.38) 83.48%)",
  },
  "&::after": {
    content: '""',
    position: "absolute",
    left: 0,
    ...(alignLines === "right" ? { top: 0 } : { bottom: 0 }),
    width: "100%",
    height: "2px",
    background:
      alignLines === "right"
        ? "linear-gradient(218.55deg,rgba(10, 199, 15, 0.38)   22.17%,rgba(5, 107, 255, 0.55) 83.48%)"
        : "linear-gradient(218.55deg, rgba(5, 107, 255, 0.55) 22.17%, rgba(10, 199, 15, 0.38) 83.48%)",
  },
}));

export const ContactBgImage = styled("img")(() => ({
  position: "absolute",
  right: 0,
  top: 0,
  width: "45%",
  height: "100%",
  objectFit: "cover",
  objectPosition: "top right",
  zIndex: -2,
}));

export const ContactInfoPopup = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  right: 0,
  width: "17.25rem",
  height: "13.5rem",
  backgroundColor: COLORS.PRIMARY,
  padding: theme.spacing(2),
  paddingRight: theme.spacing(6),
}));

export const ContactInput = styled("input")(({ theme }) => ({
  padding: theme.spacing(2),
}));
export const ContactTextArea = styled("textarea")(({ theme }) => ({
  padding: theme.spacing(2),
  resize: "none",
}));

// Contact Form Full Page
export const ContactContainer = styled(Box)(({ src, theme }) => ({
  position: "relative",
  minHeight: "55rem",
  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.47), rgba(0, 0, 0, 0.47)),url(${src})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  padding: theme.spacing(4),
  paddingTop: theme.spacing(10),
  marginTop: theme.spacing(-8),
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(1),
  },
}));

export const ContactTitleBox = styled(Stack)(({ theme }) => ({
  position: "absolute",
  left: "6%",
  top: "-6%",
  width: "100%",
  maxWidth: "22rem",
  height: "6rem",
  backgroundColor: COLORS.BLACK_SECONDARY,
  zIndex: 100,
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    position: "static",
    margin: "auto",
  },
}));

export const ContactInfo = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "-10%",
  right: "-10%",
  width: "100%",
  maxWidth: "17.25rem",
  height: "13.5rem",
  backgroundColor: COLORS.PRIMARY,
  padding: theme.spacing(2),
  paddingRight: theme.spacing(6),
  [theme.breakpoints.down("lg")]: {
    right: "-5%",
  },
  [theme.breakpoints.down("md")]: {
    position: "static",
    margin: " auto",
    marginTop: theme.spacing(8),
  },
}));
export default ContactContainer;
