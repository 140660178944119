// MUI
import {
  useMediaQuery,
  Grid,
  Typography,
  Box,
  Container,
  Stack,
} from "@mui/material";

// Shared
import IMAGES from "shared/images";
import SECTIONS_CONTENT from "shared/sections";
// Styles
import { VerticalBar } from "./about.styles";

// TODO: Make it responsive

function getAboutContent(contentList) {
  return contentList.map((content, index) => {
    const key = `about-content${index + 1}`;
    return (
      <Typography key={key} variant="subtitle1" paragraph>
        {content}
      </Typography>
    );
  });
}

function About() {
  const { ABOUT } = SECTIONS_CONTENT;
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Box component="section" sx={{ position: "relative" }}>
      {/* Left side Blue Column */}
      {!matchesSM && <VerticalBar />}
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Stack
              component="img"
              src={IMAGES.ABOUT_SECTION_IMAGE}
              sx={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
                minHeight: 300,
                maxWidth: { xs: 500, md: "100%" },
                margin: "auto",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: { xs: "center", md: "flex-start" },
            }}
          >
            <Typography variant="h2" color="initial" sx={{ marginBottom: 8 }}>
              {ABOUT.title}
            </Typography>
            <Typography
              variant="h3"
              color="initial"
              sx={{
                marginBottom: 6,
                fontSize: "1.69rem",
                color: "primary.main",
              }}
            >
              {ABOUT.subtitle}
            </Typography>
            <Box sx={{ maxWidth: "37.5rem" }}>
              {getAboutContent(ABOUT.content)}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default About;
