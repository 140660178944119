import { Box, Stack, TextField, Typography, styled } from "@mui/material";
import IMAGES from "shared/images";
import { COLORS, GRADIENTS, SHADOWS } from "styles/theme";

export const HeroSection = styled(Stack)(({ theme }) => ({
  minHeight: "650px",
  backgroundImage: `url(${IMAGES.CAREERS_HERO_BG.src})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  paddingTop: theme.spacing(8),
  marginTop: theme.spacing(-8),
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    paddingTop: theme.spacing(12),
  },
}));

export const HeroTitle = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: "4.375rem",
  color: COLORS.WHITE,
  whiteSpace: "pre-wrap",
}));

export const SearchForm = styled(Stack)(({ theme }) => ({
  maxWidth: 1200,
  width: "100%",
  margin: "auto",
  flexDirection: "row",
  backgroundColor: COLORS.CAREERS_SEARCH_BG,
  boxShadow: SHADOWS.CAREERS_SEARCH,
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
}));

export const SearchInput = styled(TextField)(() => ({
  width: "100%",
  boxSizing: "inherit",

  "& .MuiOutlinedInput-root": {
    backgroundColor: COLORS.WHITE,
    boxSizing: "inherit",
  },
}));

export const SearchSelect = styled(TextField)(() => ({
  maxWidth: 350,
  width: "100%",
  "& .MuiOutlinedInput-root": {
    backgroundColor: COLORS.WHITE,
    boxSizing: "inherit",
  },
}));

export const VerticalBar = styled(Box)(() => ({
  position: "absolute",
  left: 0,
  top: 0,
  minHeight: 900,
  height: "100%",
  width: "2.75rem",
  background: GRADIENTS.VERTICAL_BAR,
}));
