import { Route, Routes } from "react-router-dom";
import customTheme from "styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Stack from "@mui/material/Stack";
import Home from "pages/Home/home.page";
import Navigation from "components/Navigation/navigation.component";
import ContactUs from "pages/ContactUs/contactUs.page";
import AboutUs from "pages/AboutUs/aboutUs.page";
import Terms from "pages/Terms/terms.page";
import PrivacyPolicy from "pages/PrivacyPolicy/privacyPolicy.page";
import Careers from "pages/Careers/careers.page";

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <Stack
        justifyContent="space-between"
        alignItems="stretch"
        direction="column"
        sx={{ minHeight: "100vh" }}
      >
        <Routes>
          <Route path="/" element={<Navigation />}>
            <Route index element={<Home />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/terms-conditions" element={<Terms />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/careers" element={<Careers />} />
          </Route>
        </Routes>
      </Stack>
    </ThemeProvider>
  );
}

export default App;
