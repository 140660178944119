import { Link, styled } from "@mui/material";
import { COLORS } from "styles/theme";

const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== "isHovered",
})(({ isHovered }) => ({
  color: "white",
  fontFamily: "Tomato Grotesk",
  fontSize: "30px",
  fontWeight: 700,
  position: "relative",
  zIndex: 5,

  "&::after": {
    content: '""',
    width: "95%",
    position: "absolute",
    color: "red",
    left: 0,
    bottom: 6,
    zIndex: -1,
    height: "9px",
    transition: "all .5s",
    background: isHovered ? COLORS.GREEN : COLORS.PRIMARY,
  },
}));

export default StyledLink;
