import PropTypes from "prop-types";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { SectionSubTitle, SectionTitle } from "./section.styles";

function Section({ section, children, fullWidth, color }) {
  return fullWidth ? (
    <Box component="section" sx={{ backgroundColor: color }}>
      <Stack sx={{ alignItems: "center" }}>
        <SectionTitle variant="h2">{section.title}</SectionTitle>
        <SectionSubTitle>{section.subtitle}</SectionSubTitle>
      </Stack>
      <Box sx={{ width: "100%", marginTop: 6 }}>{children}</Box>
    </Box>
  ) : (
    <Box
      component="section"
      sx={[{ marginY: 12, bgColor: color }, section.backgroundImage]}
    >
      <Container>
        <Stack sx={{ alignItems: "center" }}>
          <SectionTitle variant="h2">{section.title}</SectionTitle>
          <SectionSubTitle>{section.subtitle}</SectionSubTitle>
          <Box
            sx={{
              width: "100%",
              marginTop: 6,
            }}
          >
            {children}
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
Section.propTypes = {
  section: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf(PropTypes.string),
    ]),
  ),
  children: PropTypes.element.isRequired,
  fullWidth: PropTypes.bool,
  color: PropTypes.string,
};

Section.defaultProps = {
  section: {},
  fullWidth: false,
  color: "",
};
export default Section;
