// Navbar
import SV_LOGO from "assets/images/logo.svg";
// Footer
import FACEBOOK_ICON from "assets/images/facebook.svg";
import LINKEDIN_ICON from "assets/images/linkedin.svg";
import TWITTER_ICON from "assets/images/twitter.svg";
import YOUTUBE_ICON from "assets/images/youtube_icon.svg";
import INSTAGRAM_ICON from "assets/images/instagram.svg";
// Home / Hero Section
import HERO_BACKGROUND_IMAGE from "assets/images/hero_background_image.png";
import HOME_HERO_BG_SMALL_IMAGE from "assets/images/home_hero_bg_small_image.png";
import RIGHT_ARROW_GREEN from "assets/images/right_arrow_green.svg";
// Home / About Section
import ABOUT_SECTION_IMAGE from "assets/images/about_section_image.png";
// Home / Sectors Section
import SECTORS_AGRI_BUSINESS_IMAGE from "assets/images/sectors_agri_business_image.png";
import SECTORS_REAL_ESTATE_IMAGE from "assets/images/sectors_real_estate_image.png";
import SECTORS_VENTURES_IMAGE from "assets/images/sectors_ventures_image.png";
import SECTORS_MONEY_MARKETS_IMAGE from "assets/images/sectors_money_markets_image.png";
import SECTORS_MAIN from "assets/images/sectors_main.webp";
import SECTORS_MAIN_2 from "assets/images/sectors_main_2.webp";
import SECTORS_MAIN_3 from "assets/images/sectors_main_3.webp";
import SECTORS_MAIN_4 from "assets/images/sectors_main_4.webp";
// Home / Our Process Section
import PROCESS_BACKGROUND_IMAGE from "assets/images/process_background_image.png";
import PROCESS_DISCOVERY from "assets/images/process_discovery.svg";
import PROCESS_EXECUTION from "assets/images/process_execution.svg";
import PROCESS_REVIEW from "assets/images/process_review.svg";
// Home /Open Opportunities Section
import OPPORTUNITY_LUXURY_HOUSING_COMPLEX_IMAGE from "assets/images/opportunity_luxury_housing_complex_image.png";
import OPPORTUNITY_SEMI_CONDUCTORS_IMAGE from "assets/images/opportunity_semi_conductors_image.png";
import OPPORTUNITY_GARLIC_FARM_IMAGE from "assets/images/opportunity_garlic_farm_image.png";
import OPPORTUNITY_LETTUCE_FARM_IMAGE from "assets/images/opportunity_lettuce_farm_image.png";
// Home / Partners Section
import PARTNER_LEVARI_SVG from "assets/images/partner_levari.svg";
import PARTNER_ISHARES_SVG from "assets/images/partner_ishares.svg";
import PARTNER_VANGUARD_SVG from "assets/images/partner_vanguard.svg";
import PARTNER_INTERACTIVE_BROKERS_SVG from "assets/images/partner_interactive_brokers.svg";
import PARTNER_AFC_EXPERTS_SVG from "assets/images/partner_afc_expert.svg";
import PARTNER_DRIVE_WEALTH_SVG from "assets/images/partner_drive_wealth.svg";
// Home / Blog Section
import BLOG_BLACK_RECTANGLES from "assets/images/blog_black_rectangles.svg";
import BLOG_POST_EXAMPLE_IMAGE from "assets/images/blog_post_example_image.png";
import BLOG_POST_ITEM_1 from "assets/images/blog_item_1.png";
import BLOG_POST_TEST from "assets/images/blog_test.png";

import BLOG_QUOTATION_MARK from "assets/images/blog_quotation_mark.svg";

// Contact
import CONTACT_BG_IMAGE from "assets/images/contact_bg_image.png";
import CONTACT_BUTTON from "assets/images/contact_button.svg";

// AboutUs
import ABOUT_HERO_BG_IMAGE from "assets/images/about_hero_bg_image.png";
import { ReactComponent as ABOUT_DOUBLE_QUOTES_SVG } from "assets/images/about_double_quotes.svg";

import ABOUT_COMPANY_HISTORY_IMAGE from "assets/images/about_company_history_image.png";
import ABOUT_MISSION_VISION_IMAGE from "assets/images/about_mission_vision_image.png";
import ABOUT_MANAGEMENT_TEAM_IMAGE from "assets/images/about_management_team_image.png";
import ABOUT_COMMUNITY_INVOLVEMENT_IMAGE from "assets/images/about_community_involvement_image.png";
import ABOUT_COMPANY_CULTURE_IMAGE from "assets/images/about_company_culture_image.png";
import ABOUT_PARTNERSHIPS_IMAGE from "assets/images/about_partnerships_image.png";

// Terms
import { ReactComponent as TERMS_TITLE_ICON_SVG } from "assets/images/terms_title_icon.svg";
import { ReactComponent as PRIVACY_POLICY_TITLE_ICON_SVG } from "assets/images/privacy_policy_title_icon.svg";

import CAREERS_HERO_BG_IMAGE from "assets/images/careers_hero_bg.png";
import CAREERS_INTRO_IMAGE from "assets/images/careers_intro.png";

// career page
import JOIN_SV from "assets/images/join_sv.png";
import WHY_JOIN_1 from "assets/images/why_join_1.png";
import WHY_JOIN_2 from "assets/images/why_join_2.png";
import WHY_JOIN_3 from "assets/images/why_join_3.png";
import WHY_JOIN_4 from "assets/images/why_join_4.png";
import WHY_JOIN_5 from "assets/images/why_join_5.png";
import WHY_JOIN_BG_1 from "assets/images/why_join_bg_1.png";
import WHY_JOIN_BG_2 from "assets/images/why_join_bg_2.png";
import WHY_JOIN_BG_3 from "assets/images/why_join_bg_3.png";
import WHY_JOIN_BG_4 from "assets/images/why_join_bg_4.png";
import WHY_JOIN_BG_5 from "assets/images/why_join_bg_5.png";
import BENEFITS_ICON_1 from "assets/images/benefits_icon_1.svg";
import BENEFITS_ICON_2 from "assets/images/benefits_icon_2.svg";
import BENEFITS_ICON_3 from "assets/images/benefits_icon_3.svg";
import BENEFITS_ICON_4 from "assets/images/benefits_icon_4.svg";
import BENEFITS_ICON_5 from "assets/images/benefits_icon_5.svg";
import OUR_TEAM_1 from "assets/images/our_team_1.png";
import CAREER_POPUP_FORM from "assets/images/career_popup_form.png";

const IMAGES = {
  // Navbar
  SV_LOGO,
  // Footer
  FACEBOOK_ICON,
  LINKEDIN_ICON,
  TWITTER_ICON,
  YOUTUBE_ICON,
  INSTAGRAM_ICON,

  // Hero Section
  HERO_BACKGROUND_IMAGE,
  HOME_HERO_BG_SMALL_IMAGE,
  RIGHT_ARROW_GREEN,
  // About Section
  ABOUT_SECTION_IMAGE,
  // Sectors Section
  SECTORS_AGRI_BUSINESS_IMAGE,
  SECTORS_REAL_ESTATE_IMAGE,
  SECTORS_VENTURES_IMAGE,
  SECTORS_MONEY_MARKETS_IMAGE,
  SECTORS_MAIN,
  SECTORS_MAIN_2,
  SECTORS_MAIN_3,
  SECTORS_MAIN_4,
  // Our Process Section
  PROCESS_BACKGROUND_IMAGE,
  PROCESS_DISCOVERY,
  PROCESS_EXECUTION,
  PROCESS_REVIEW,
  // Open Opportunities Section
  OPPORTUNITY_LUXURY_HOUSING_COMPLEX_IMAGE,
  OPPORTUNITY_SEMI_CONDUCTORS_IMAGE,
  OPPORTUNITY_GARLIC_FARM_IMAGE,
  OPPORTUNITY_LETTUCE_FARM_IMAGE,
  // Partners Section
  PARTNER_LEVARI_SVG,
  PARTNER_ISHARES_SVG,
  PARTNER_VANGUARD_SVG,
  PARTNER_INTERACTIVE_BROKERS_SVG,
  PARTNER_AFC_EXPERTS_SVG,
  PARTNER_DRIVE_WEALTH_SVG,
  // Blog Section
  BLOG_BLACK_RECTANGLES,
  BLOG_POST_EXAMPLE_IMAGE,
  BLOG_POST_TEST,
  BLOG_POST_ITEM_1,
  BLOG_QUOTATION_MARK,

  // Contact
  CONTACT_BG_IMAGE,
  CONTACT_BUTTON,

  // AboutUs
  ABOUT_HERO_BG: {
    src: ABOUT_HERO_BG_IMAGE,
    alt: "",
  },

  ABOUT_DOUBLE_QUOTES_SVG,

  ABOUT_COMPANY_HISTORY: {
    src: ABOUT_COMPANY_HISTORY_IMAGE,
    alt: "",
  },
  ABOUT_MISSION_VISION: {
    src: ABOUT_MISSION_VISION_IMAGE,
    alt: "",
  },
  ABOUT_MANAGEMENT_TEAM: {
    src: ABOUT_MANAGEMENT_TEAM_IMAGE,
    alt: "",
  },
  ABOUT_COMMUNITY_INVOLVEMENT: {
    src: ABOUT_COMMUNITY_INVOLVEMENT_IMAGE,
    alt: "",
  },
  ABOUT_COMPANY_CULTURE: {
    src: ABOUT_COMPANY_CULTURE_IMAGE,
    alt: "",
  },
  ABOUT_PARTNERSHIPS: {
    src: ABOUT_PARTNERSHIPS_IMAGE,
    alt: "",
  },
  // Terms
  TERMS_TITLE_ICON_SVG,
  // Priavcy Policy
  PRIVACY_POLICY_TITLE_ICON_SVG,

  CAREERS_HERO_BG: {
    src: CAREERS_HERO_BG_IMAGE,
    alt: "",
  },
  CAREERS_INTRO: {
    src: CAREERS_INTRO_IMAGE,
    alt: "",
  },
  // career page
  JOIN_SV: {
    src: JOIN_SV,
    alt: "",
  },
  WHY_JOIN_1: {
    src: WHY_JOIN_1,
    alt: "",
  },
  WHY_JOIN_2: {
    src: WHY_JOIN_2,
    alt: "",
  },
  WHY_JOIN_3: {
    src: WHY_JOIN_3,
    alt: "",
  },
  WHY_JOIN_4: {
    src: WHY_JOIN_4,
    alt: "",
  },
  WHY_JOIN_5: {
    src: WHY_JOIN_5,
    alt: "",
  },
  WHY_JOIN_BG_1: {
    src: WHY_JOIN_BG_1,
    alt: "",
  },
  WHY_JOIN_BG_2: {
    src: WHY_JOIN_BG_2,
    alt: "",
  },
  WHY_JOIN_BG_3: {
    src: WHY_JOIN_BG_3,
    alt: "",
  },
  WHY_JOIN_BG_4: {
    src: WHY_JOIN_BG_4,
    alt: "",
  },
  WHY_JOIN_BG_5: {
    src: WHY_JOIN_BG_5,
    alt: "",
  },
  BENEFITS_ICON_1: {
    src: BENEFITS_ICON_1,
    alt: "",
  },
  BENEFITS_ICON_2: {
    src: BENEFITS_ICON_2,
    alt: "",
  },
  BENEFITS_ICON_3: {
    src: BENEFITS_ICON_3,
    alt: "",
  },
  BENEFITS_ICON_4: {
    src: BENEFITS_ICON_4,
    alt: "",
  },
  BENEFITS_ICON_5: {
    src: BENEFITS_ICON_5,
    alt: "",
  },
  OUR_TEAM_1: {
    src: OUR_TEAM_1,
    alt: "",
  },
  CAREER_POPUP_FORM: {
    src: CAREER_POPUP_FORM,
    alt: "",
  },
};

export default IMAGES;
