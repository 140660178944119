import React from "react";
import IMAGES from "shared/images";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  ProcessCardBody,
  ProcessCard,
  ProcessCardTitle,
} from "./process.styles";

const ProcessesData = [
  {
    title: "Discovery",
    image: IMAGES.PROCESS_DISCOVERY,
    bodyList: [
      `Evaluate Current Assets`,
      `Analyze Investment objectives`,
      `Investment Research`,
    ],
  },
  {
    title: "Execution",
    image: IMAGES.PROCESS_EXECUTION,
    bodyList: [
      `Project Management`,
      `Partners with Leading financial institutions`,
      `Certified Auditors & Legal Services`,
    ],
  },
  {
    title: "Review",
    image: IMAGES.PROCESS_REVIEW,
    bodyList: [
      `Real-time Monitoring & reporting`,
      `Intelligence & benchmarking`,
      `Rebalance of Assets`,
    ],
  },
];

const getProcessBody = (processBodyList) =>
  processBodyList.map((content, index) => {
    const key = `process-${index}`;
    return (
      <ProcessCardBody key={key} component="li" paragraph>
        {content}
      </ProcessCardBody>
    );
  });

const getProcesses = (processes) =>
  processes.map((process) => (
    <Grid item key={`process-${process.title}`} md={3} xs={12}>
      <ProcessCard>
        {/* Card Image */}
        <Box component="img" src={process.image} />
        {/* Card Title */}
        <ProcessCardTitle variant="h3">{process.title}</ProcessCardTitle>
        {/* Card Body */}
        <Box component="ul" sx={{ paddingLeft: 3 }}>
          {getProcessBody(process.bodyList)}
        </Box>
      </ProcessCard>
    </Grid>
  ));

function Process() {
  return (
    <Grid container justifyContent="space-around">
      {getProcesses(ProcessesData)}
    </Grid>
  );
}

export default Process;
