import { styled } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { COLORS } from "styles/theme";

export const ProcessCard = styled(Stack)(({ theme }) => ({
  minHeight: "375px",
  maxWidth: "250px",
  gap: theme.spacing(4),
  margin: "auto",
}));

export const ProcessCardTitle = styled(Typography)(() => ({
  fontFamily: "Tomato Grotesk",
  width: "fit-content",
  margin: "auto",
  fontWeight: 700,
  fontSize: "24px",
  color: COLORS.BLACK,

  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: "5px",
    zIndex: -1,
    height: "8px",
    width: "75%",
    backgroundColor: COLORS.GREEN,
  },
}));

export const ProcessCardBody = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: "14px",
  color: COLORS.BLACK,
}));
