import { Box, Typography, styled } from "@mui/material";

export const BenefitsWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(12),
  marginBottom: theme.spacing(4),
}));
export const BenefitsCardsContent = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(6),
}));
export const BenefitsCard = styled(Box)(({ theme }) => ({
  textAlign: "center",
  paddingRight: "20px",
}));
export const BenefitsCardImg = styled("img")(({ theme }) => ({
  objectFit: "contain",
  maxWidth: "56px",
  width: "100%",
  marginBottom: "8px",
}));
export const BenefitsCardTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: "13px",
}));
export const BenefitsCardDesc = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
}));
