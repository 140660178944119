// React
import { useState } from "react";

// PropTypes
import PropTypes from "prop-types";

// MUI

import {
  useMediaQuery,
  Modal,
  Box,
  Button,
  Typography,
  Link,
  Stack,
} from "@mui/material";

// Shared
import IMAGES from "shared/images";
import SECTIONS_CONTENT from "shared/sections";

// Theme
import { COLORS } from "styles/theme";

import {
  BgLines,
  BorderBox,
  ContactBgImage,
  ContactContainer,
  ContactInfo,
  ContactInfoPopup,
  ContactInput,
  ContactPopupContainer,
  ContactTextArea,
  ContactTitle,
  ContactTitleBox,
  PopupTitleBox,
} from "./contact.styles";

const { name, address, phone } = SECTIONS_CONTENT.CONTACT_INFO;

function Form({ handleSubmit }) {
  return (
    <Stack
      component="form"
      justifyContent="space-between"
      sx={{
        width: "100%",
        maxWidth: 500,
        height: "100%",
        zIndex: 100,
        gap: 2,
        margin: { xs: "auto", md: 0 },
      }}
      onSubmit={handleSubmit}
    >
      <Stack>
        <Typography
          sx={{ color: COLORS.WHITE, fontWeight: 400 }}
          component="label"
          htmlFor="name"
        >
          Name
        </Typography>
        <ContactInput id="name" name="name" />
      </Stack>

      <Stack>
        <Typography
          sx={{ color: COLORS.WHITE, fontWeight: 400 }}
          component="label"
          htmlFor="email"
        >
          Email
        </Typography>
        <ContactInput type="email" id="email" name="email" />
      </Stack>
      <Stack>
        <Typography
          sx={{ color: COLORS.WHITE, fontWeight: 400 }}
          component="label"
          htmlFor="company"
        >
          Location of the Company
        </Typography>
        <ContactInput id="company" name="company" />
      </Stack>
      <Stack>
        <Typography
          sx={{ color: COLORS.WHITE, fontWeight: 400 }}
          component="label"
          htmlFor="message"
        >
          Message
        </Typography>
        <ContactTextArea id="message" name="message" rows="5" />
      </Stack>
      <Button
        variant="contained"
        color="primary"
        sx={{ alignSelf: "flex-end", paddingY: 2, paddingX: 4 }}
        type="submit"
      >
        Send
      </Button>
    </Stack>
  );
}

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};
export function ContactPopup() {
  const [open, setOpen] = useState(false);
  const { CONTACT_BG_IMAGE, CONTACT_BUTTON } = IMAGES;
  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
  };
  return (
    <>
      <Button
        sx={{ position: "fixed", right: "2%", bottom: "8%" }}
        onClick={() => setOpen(true)}
      >
        <Box component="img" src={CONTACT_BUTTON} />
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-contactUs"
        aria-describedby="modal-contact-form"
      >
        <ContactPopupContainer>
          <PopupTitleBox>
            <ContactTitle>Get In Touch!</ContactTitle>
          </PopupTitleBox>
          <BorderBox>
            <Form handleSubmit={handleSubmit} />

            <BgLines alignLines="left" />
            <BgLines alignLines="right" />
          </BorderBox>
          <ContactBgImage src={CONTACT_BG_IMAGE} />
          <ContactInfoPopup>
            <Typography
              component="h4"
              sx={{ color: COLORS.WHITE, fontSize: "1.5rem" }}
              gutterBottom
            >
              Address
            </Typography>
            <Typography sx={{ color: COLORS.WHITE, fontWeight: 400 }} paragraph>
              {name}
            </Typography>
            <Typography sx={{ color: COLORS.WHITE, fontWeight: 400 }} paragraph>
              {address}
            </Typography>
            <Link
              href={`tel:${phone}`}
              sx={{ color: COLORS.WHITE, fontWeight: 400 }}
            >
              {phone}
            </Link>
          </ContactInfoPopup>
        </ContactPopupContainer>
      </Modal>
    </>
  );
}

export function ContactFullPage() {
  const { CONTACT_BG_IMAGE } = IMAGES;
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <ContactContainer src={CONTACT_BG_IMAGE}>
      <BorderBox>
        <ContactTitleBox>
          <ContactTitle variant="h2">Get In Touch!</ContactTitle>
        </ContactTitleBox>

        <Form handleSubmit={handleSubmit} />

        {!matchesSM && (
          <>
            <BgLines alignLines="left" />
            <BgLines alignLines="right" />

            <ContactInfo>
              <Typography
                component="h4"
                sx={{ color: COLORS.WHITE, fontSize: "1.5rem" }}
                gutterBottom
              >
                Address
              </Typography>
              <Typography
                sx={{ color: COLORS.WHITE, fontWeight: 400 }}
                paragraph
              >
                {name}
              </Typography>
              <Typography
                sx={{ color: COLORS.WHITE, fontWeight: 400 }}
                paragraph
              >
                {address}
              </Typography>
              <Link
                href={`tel:${phone}`}
                sx={{ color: COLORS.WHITE, fontWeight: 400 }}
              >
                {phone}
              </Link>
            </ContactInfo>
          </>
        )}
      </BorderBox>
    </ContactContainer>
  );
}
