import PropTypes from "prop-types";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import SECTIONS_CONTENT from "shared/sections";

import IMAGES from "shared/images";
import { COLORS } from "styles/theme";
import { WhyJoinBackImg, WhyJoinDesc, WhyJoinTitle } from "./WhyJoin.style";

function NextArrow(props) {
  const { onClick } = props;
  return (
    <IconButton
      sx={{
        position: "absolute",
        top: "105%",
        left: { xs: "63%", md: "53%" },
        transform: "translate(-50%)",
        zIndex: 100,
        color: COLORS.PRIMARY,
        border: "1px solid #066CFE",
        borderRadius: "inherit",
        "&:hover": {
          backgroundColor: COLORS.PRIMARY,
          color: COLORS.WHITE,
        },
      }}
      onClick={onClick}
    >
      <ArrowForwardIosOutlinedIcon />
    </IconButton>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <IconButton
      sx={{
        position: "absolute",

        top: "105%",
        left: { xs: "37%", md: "47%" },
        transform: "translate(-50%)",
        zIndex: 100,
        color: COLORS.PRIMARY,
        border: "1px solid #066CFE",
        borderRadius: "inherit",
        "&:hover": {
          backgroundColor: COLORS.PRIMARY,
          color: COLORS.WHITE,
        },
      }}
      onClick={onClick}
    >
      <ArrowBackIosOutlinedIcon />
    </IconButton>
  );
}

function getTeamMembers(members) {
  const { ABOUT_DOUBLE_QUOTES_SVG } = IMAGES;
  return members?.map((member) => {
    return (
      <Grid
        container
        key={member.title}
        sx={{ display: "flex !important", paddingX: 1 }}
        rowGap={2}
      >
        <Grid item xs={12} md={6}>
          <Stack
            gap={4}
            justifyContent="center"
            sx={{ position: "relative", height: "100%", paddingLeft: 2 }}
          >
            <WhyJoinTitle variant="h3">{member.title}</WhyJoinTitle>
            <WhyJoinDesc sx={{ paddingRight: { lg: 5 } }} variont="body1">
              {member.desc}
            </WhyJoinDesc>
            <WhyJoinBackImg src={member.bgImg.src} />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={member.img.src}
            sx={{
              width: "100%",
              height: "100%",
              maxHeight: 450,
              maxWidth: 450,
              objectFit: "contain",
              margin: "auto",
            }}
          />
        </Grid>
      </Grid>
    );
  });
}

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

function WhyJoin() {
  const { CAREERS_WHY_JOIN } = SECTIONS_CONTENT;
  return (
    <Container component="section" sx={{ marginY: 4 }}>
      <Typography
        variant="h3"
        textAlign="center"
        sx={{ marginBottom: 6, color: "#404040" }}
      >
        {CAREERS_WHY_JOIN.title}
      </Typography>

      <Slider {...settings}>{getTeamMembers(CAREERS_WHY_JOIN.cards)}</Slider>
    </Container>
  );
}

PrevArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
};

NextArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
};
export default WhyJoin;
