import { useEffect, useState, cloneElement, Fragment } from "react";
import { useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Stack,
  SwipeableDrawer,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { HashLink } from "react-router-hash-link";

import { COLORS } from "styles/theme";

import IMAGES from "shared/images";
import { MenuItemSector, NavbarLink, NavButton } from "./navbar.styles";

// TODO: Refactor to styles
// Add Drawer for small screens
// Make It Responsive

const NavLinksData = [
  {
    name: "Home",
    url: "/#",
  },
  {
    name: "Sectors",
    url: "/Sectors/#",
  },
  {
    name: "About Us",
    url: "/about-us/#",
  },
];
const MenuLinksFeaturesS = [
  { name: "Agri Business", url: "/#" },
  { name: "Real Estate", url: "/#" },
  { name: "Money Markets", url: "/#" },
  { name: "Venture Capitals", url: "/#" },
];

function getDrawerLinks(drawerLinksList, toggleDrawer, MenuLinksFeaturesS) {
  return drawerLinksList.map((drawerLink) => {
    if (drawerLink.name === "Sectors") {
      return (
        // in sidebar

        <Fragment key={drawerLink.name}>
          <ListItem
            key={drawerLink.name}
            sx={{ marginY: 1 }}
            component={HashLink}
            to={drawerLink.url}
            onClick={toggleDrawer}
            onKeyDown={toggleDrawer}
          >
            {drawerLink.name}
          </ListItem>
          {MenuLinksFeaturesS.map((item) => (
            <ListItem
              sx={{ marginY: 1 }}
              component={HashLink}
              to={item.url}
              onClick={toggleDrawer}
              onKeyDown={toggleDrawer}
              key={`drawer-link ${item.name}`}
            >
              {item.name}
            </ListItem>
          ))}
          <Divider />
        </Fragment>
      );
    }
    return (
      <Fragment key={drawerLink.name}>
        <ListItem
          key={drawerLink.name}
          sx={{ marginY: 1 }}
          component={HashLink}
          to={drawerLink.url}
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          {drawerLink.name}
        </ListItem>
        <Divider />
      </Fragment>
    );
  });
}

function getNavLinks(navLinksList, activeLink, sectorMenue) {
  return navLinksList.map((navLink, index) => {
    if (navLink.name === "Sectors") {
      return (
        <Fragment key={`${navLink.name}-${index}`}>
          {/* <NavbarLink
            component={HashLink}
            key={navLink.name}
            isActive={activeLink === navLink.url}
            to={navLink.url}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            {navLink.name}
          </NavbarLink> */}
          {sectorMenue(navLink)}
        </Fragment>
      );
    }
    return (
      <NavbarLink
        component={HashLink}
        key={navLink.name}
        isActive={activeLink === navLink.url}
        to={navLink.url}
      >
        {navLink.name}
      </NavbarLink>
    );
  });
}

function ElevationScroll(props) {
  const { children } = props;

  return cloneElement(children, {
    elevation: props.trigger ? 4 : 0,
  });
}

function Navbar() {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  const initialLink = NavLinksData[0].url;
  const [activeLink, setActiveLink] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const location = useLocation();
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function sectorMenue(navLink) {
    return (
      <Fragment key={navLink.name}>
        <NavbarLink
          component={Button}
          isActive={activeLink === navLink.url}
          to={navLink.url}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {navLink.name}
          <KeyboardArrowDownIcon />
        </NavbarLink>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {MenuLinksFeaturesS.map((item) => (
            <MenuItemSector
              key={`item name :${item.name}`}
              onClick={handleClose}
            >
              {item.name}
            </MenuItemSector>
          ))}
        </Menu>
      </Fragment>
    );
  }
  useEffect(() => {
    const currentLink = NavLinksData.find((link) => {
      if (location.hash) {
        return link.url === location.pathname + location.hash;
      }
      return link.url === `${location.pathname}#`;
    });

    if (currentLink) {
      setActiveLink(currentLink.url);
    }
  }, [location]);

  const toggleDrawer = (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer((prev) => !prev);
  };

  return (
    <>
      <ElevationScroll trigger={trigger}>
        <AppBar
          component="nav"
          sx={[
            {
              backgroundColor: "transparent",
              py: 3,
            },
            trigger && {
              backgroundColor: " rgb(122, 122, 122,.5)",
              boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
              backdropFilter: "blur( 7.5px )",
              WebkitBackdropFilter: " blur( 7.5px )",
              border: "1px solid rgba( 255, 255, 255, 0.18 )",
            },
          ]}
        >
          <Container>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {matchesSM ? (
                <IconButton onClick={toggleDrawer}>
                  <MenuIcon sx={{ color: COLORS.WHITE }} fontSize="large" />
                </IconButton>
              ) : (
                <HashLink to={initialLink}>
                  <Box component="img" src={IMAGES.SV_LOGO} />
                </HashLink>
              )}
              <Stack
                direction="row"
                alignItems="center"
                sx={{ gap: { xs: 1, md: 4 } }}
              >
                {!matchesSM &&
                  getNavLinks(NavLinksData, activeLink, sectorMenue)}
                {/* <NavButton href="#sign-in">Sign in</NavButton> */}
                {/* <Button
                  variant="contained"
                  sx={{
                    height: "50px",
                    paddingX: 3,
                    width: { xs: 90, md: "initial" },
                    backgroundColor: "rgba(63, 63, 63, 0.23)",
                    "&:hover": {
                      backgroundColor:
                        "linear-gradient(0deg, rgba(0, 0, 0, 0.18), rgba(0, 0, 0, 0.18)), rgba(63, 63, 63, 0.23)",
                    },
                  }}
                  href="#sign-in"
                >
                  Sign in
                </Button> */}
              </Stack>
            </Stack>
          </Container>
        </AppBar>
      </ElevationScroll>
      <Toolbar />

      {/* Drawer */}
      <SwipeableDrawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        <List role="presentation" sx={{ width: "240px" }}>
          {getDrawerLinks(NavLinksData, toggleDrawer, MenuLinksFeaturesS)}
        </List>
      </SwipeableDrawer>
    </>
  );
}

export default Navbar;
