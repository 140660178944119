import { useCallback, useState } from "react";

import { Box, Button, Container, MenuItem, Stack } from "@mui/material";
import PropTypes from "prop-types";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import SECTIONS_CONTENT from "shared/sections";

import {
  HeroSection,
  HeroTitle,
  SearchForm,
  SearchInput,
  SearchSelect,
} from "./careersContent.styles";

const defaultFormFields = {
  title: "",
  country: "",
  workLocation: "",
  type: "",
};

function Hero(props) {
  const { CAREERS_CONTENT } = SECTIONS_CONTENT;
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { setVacancies } = props;
  const { title, country, workLocation, type } = formFields;

  const handleSearchVacancies = useCallback(
    (e) => {
      e.preventDefault();
      const filteredVacancies = CAREERS_CONTENT.vacancies.filter((vacancy) => {
        return (
          vacancy.title.toLowerCase().includes(title) &&
          vacancy.country.toLowerCase().includes(country) &&
          vacancy.workLocation.toLowerCase().includes(workLocation) &&
          vacancy.type.toLowerCase().includes(type)
        );
      });
      if (filteredVacancies.length) setVacancies(filteredVacancies);
      else setVacancies(CAREERS_CONTENT.vacancies);
    },
    [
      title,
      workLocation,
      country,
      type,
      CAREERS_CONTENT.vacancies,
      setVacancies,
    ],
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value.toLowerCase() });
  };

  return (
    <HeroSection component="section">
      <Container>
        <Stack sx={{ rowGap: 4 }}>
          <Box>
            <HeroTitle variant="h1">{CAREERS_CONTENT.heroTitle}</HeroTitle>
          </Box>
          <SearchForm component="form" onSubmit={handleSearchVacancies}>
            <SearchInput
              id="title"
              placeholder="Job Title Or Keyword"
              variant="outlined"
              InputProps={{
                startAdornment: <SearchOutlinedIcon />,
              }}
              value={title}
              name="title"
              onChange={handleChange}
            />

            <SearchSelect
              select
              InputProps={{
                startAdornment: <LocationOnIcon color="primary" />,
              }}
              value={country}
              name="country"
              onChange={handleChange}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="egypt">Egypt</MenuItem>
            </SearchSelect>
            <SearchSelect
              select
              value={workLocation}
              name="workLocation"
              onChange={handleChange}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="remotely">Remotely</MenuItem>
              <MenuItem value="onsite">Onsite</MenuItem>
              <MenuItem value="hybrid">Hybrid</MenuItem>
            </SearchSelect>
            <SearchSelect
              select
              value={type}
              name="type"
              onChange={handleChange}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="full">Full Time</MenuItem>
              <MenuItem value="part">Part Time</MenuItem>
            </SearchSelect>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ width: 200 }}
            >
              Search
            </Button>
          </SearchForm>
        </Stack>
      </Container>
    </HeroSection>
  );
}

Hero.propTypes = {
  setVacancies: PropTypes.func.isRequired,
};

export default Hero;
