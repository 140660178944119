import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import { GRADIENTS } from "styles/theme";

export const VerticalBar = styled(Box)(() => ({
  position: "absolute",
  left: 0,
  top: 0,
  height: "56.5rem",
  width: "2.75rem",
  background: GRADIENTS.VERTICAL_BAR,
}));

export default VerticalBar;
