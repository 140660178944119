import PropTypes from "prop-types";
// MUI
import { Box, Typography } from "@mui/material";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import ShareIcon from "@mui/icons-material/Share";
// Styles
import {
  CardBox,
  CardButton,
  CardContentBox,
  CardContentDetails,
  CardImage,
  CardTitle,
} from "./card.style";

function CardComponent({ title, image, description, link }) {
  return (
    <CardBox>
      {image && <CardImage src={image} />}
      <CardContentBox>
        <CardTitle
          gutterBottom
          variant="h3"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <CardContentDetails dangerouslySetInnerHTML={{ __html: description }} />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mt: "auto" }}
        >
          <CardButton
            sx={{ display: "flex" }}
            variant="text"
            href={link}
            target="_blank"
          >
            Read More
            <ArrowForwardIcon sx={{ ml: 1 }} />
          </CardButton>
          <ShareIcon sx={{ color: "#056BFF" }} />
        </Box>
      </CardContentBox>
    </CardBox>
  );
}
CardComponent.defaultProps = {
  title: "",
  image: "",
  description: "",
  link: "",
};

CardComponent.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
};

export default CardComponent;
