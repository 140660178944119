import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import ShareIcon from "@mui/icons-material/Share";

import usePosts, { useFeaturedPost } from "hooks/usePosts";

import CardComponent from "./card/Card.component";
import {
  CardButton,
  CardContentBox,
  CardContentDetails,
  CardTitle,
  CardImage,
} from "./card/card.style";

// TODO:
// Add Carousel for the posts
// Responsive Design

function Blog() {
  const posts = usePosts();
  const featuredPost = useFeaturedPost();
  return (
    <Container>
      <Stack gap={4}>
        {Object.keys(featuredPost).length > 0 && (
          <Box
            sx={{
              bgcolor: "#F8F8F8",
              boxShadow: "0px 14px 30px rgba(157, 157, 157, 0.15)",
            }}
          >
            <Grid container rowSpacing={2}>
              <Grid item xs={12} md={6}>
                <CardImage src={featuredPost.jetpack_featured_media_url} />
              </Grid>
              <Grid item xs={12} md={6}>
                <CardContentBox sx={{ pr: 4 }}>
                  <CardTitle
                    gutterBottom
                    variant="h3"
                    dangerouslySetInnerHTML={{
                      __html: featuredPost?.title.rendered,
                    }}
                  />
                  <CardContentDetails
                    dangerouslySetInnerHTML={{
                      __html: featuredPost.excerpt.rendered,
                    }}
                  />
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mt: 5 }}
                  >
                    <CardButton
                      sx={{ display: "flex" }}
                      variant="text"
                      href={featuredPost?.link}
                      target="_blank"
                    >
                      Read More
                      <ArrowForwardIcon sx={{ ml: 1 }} />
                    </CardButton>
                    <ShareIcon sx={{ color: "#056BFF" }} />
                  </Box>
                </CardContentBox>
              </Grid>
            </Grid>
          </Box>
        )}

        <Grid container spacing={4}>
          {posts?.map((post) => (
            <Grid
              key={`post-${post?.title.rendered}`}
              mb={2}
              item
              xs={12}
              sm={6}
              md={4}
              sx={{ minHeight: "100%" }}
            >
              <CardComponent
                title={post?.title.rendered}
                description={post?.excerpt.rendered}
                link={post?.link}
                image={post?.jetpack_featured_media_url}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Container>
  );
}

export default Blog;
