import { Button, Link, MenuItem, styled } from "@mui/material";
import { COLORS } from "styles/theme";

export const NavbarLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ isActive }) => ({
  color: COLORS.WHITE,
  fontWeight: 700,
  fontSize: isActive ? "18px" : "14px",
  position: "relative",
  transition: "all .2s",
  "&::after": {
    content: '""',
    width: "75%",
    position: "absolute",
    left: 0,
    bottom: 0,
    height: "8px",
    zIndex: -1,
    background:
      "linear-gradient(360deg, #056BFF 0%, rgba(189, 216, 255, 0.05) 99.99%, rgba(5, 107, 255, 0.05) 100%)",
    transition: "opacity .2s",
    opacity: isActive ? 1 : 0,
  },
}));

export const NavButton = styled(Button)(({ theme }) => ({
  color: COLORS.WHITE,
  height: "50px",
  backgroundColor:
    "linear-gradient(0deg, rgba(0, 0, 0, 0.18), rgba(0, 0, 0, 0.18)), rgba(63, 63, 63, 0.23)",
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  "&:hover": {
    backgroundColor: "rgba(63, 63, 63, 0.23)",
  },

  [theme.breakpoints.down("md")]: {
    width: 100,
  },
}));
export const MenuItemSector = styled(MenuItem)(() => ({
  width: "238px",
  paddingLeft: "26px",
  marginLeft: "9px",
  marginRight: "9px",
  "&:hover": {
    backgroundColor: "#E7E3E3",
  },
}));
