import About from "components/About/about.component";
import Blog from "components/Blog/blog.component";
import ContactUs from "components/ContactUs/ContactUs.component";
import Hero from "components/Hero/hero.component";
import Process from "components/Process/process.component";
import Section from "components/Section/section.component";
import SectorsComp from "components/Sectors/SectorsComp.component";
import SECTIONS_CONTENT from "shared/sections";

function Home() {
  return (
    <>
      <Hero />
      <About />
      <Section section={SECTIONS_CONTENT.SECTORS}>
        <SectorsComp />
      </Section>
      <Section section={SECTIONS_CONTENT.OUR_PROCESS}>
        <Process />
      </Section>
      {/* <Section section={SECTIONS_CONTENT.OPPORTUNITIES}>
        <Opportunities />
      </Section>
      <Section section={SECTIONS_CONTENT.PARTNERS}>
        <Partners />
      </Section> */}
      <Section color="#fff" section={SECTIONS_CONTENT.BLOG} fullWidth>
        <Blog />
      </Section>
      <ContactUs />
      {/* Added SalesIQ Chat popup */}
      {/* {!matchesSM && <ContactForm variant="popup" />} */}
    </>
  );
}

export default Home;
