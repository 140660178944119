import {
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { COLORS } from "styles/theme";

import SECTIONS_CONTENT from "shared/sections";

import { SearchInput } from "./careersContent.styles";

function JobForm() {
  const { CAREERS_CONTENT } = SECTIONS_CONTENT;

  return (
    <Box
      component="section"
      sx={{
        paddingY: 8,
        minHeight: 700,
        position: "relative",
        background: `url(${CAREERS_CONTENT.applyBgImage})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container>
        <Typography
          variant="h2"
          textAlign="center"
          color="primary"
          sx={{ marginBottom: 4 }}
        >
          {CAREERS_CONTENT.applyTitle}
        </Typography>
        <Grid container rowGap={2} columnSpacing={6} justifyContent="flex-end">
          <Grid item xs={12} md={6}>
            <Typography component="label" htmlFor="name">
              Name
            </Typography>
            <SearchInput
              id="name"
              placeholder="Enter your name here"
              variant="outlined"
              name="name"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography component="label" htmlFor="comment">
              Comment
            </Typography>
            <SearchInput
              id="comment"
              placeholder="Any comments?"
              variant="outlined"
              name="comment"
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography component="label" htmlFor="phone">
              Phone Number
            </Typography>
            <SearchInput
              id="phone"
              placeholder="Enter your phone number"
              variant="outlined"
              name="phone"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack component="label" htmlFor="resume">
              <Typography>Resume</Typography>
              <SearchInput
                disabled
                placeholder="Upload your resume"
                sx={{ zIndex: -1 }}
              />
              <Button
                component="label"
                htmlFor="resume"
                variant="outlined"
                sx={{ width: 120, marginLeft: "auto", marginTop: 2 }}
              >
                Upload
                <Box component="input" id="resume" type="file" hidden />
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography component="label" htmlFor="email">
              Email
            </Typography>
            <SearchInput
              id="email"
              placeholder="Enter your email here"
              variant="outlined"
              type="email"
              name="name"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              direction="row"
              gap={2}
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <Checkbox
                name="terms"
                sx={{
                  color: COLORS.GREEN,
                  "&.Mui-checked": {
                    color: COLORS.GREEN,
                  },
                }}
              />
              <Typography>
                I agree that Salem Ventures is a technology-first-driven asset
                management institution that provides reliable, experience-based
                consultancy to its wide book of clients.
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4} sx={{ display: "flex" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginLeft: "auto", width: 120 }}
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
export default JobForm;
