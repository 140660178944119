import { Box, Typography, styled } from "@mui/material";
import { COLORS } from "styles/theme";

export const OurTeamWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));
export const OurTeamCardsContent = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(6),
}));
export const OurTeamCard = styled(Box)(({ theme }) => ({
  position: "relative",
  maxHeight: "230px",
  maxWidth: "230px",
  width: "100%",
  height: "100%",
}));
export const OurTeamCardImg = styled("img")(({ theme }) => ({
  objectFit: "contain",
  width: "100%",
  height: "100%",
}));
export const OurTeamCardContentWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "-30px",
  left: "50%",
  transform: "translateX(-50%)",
  background: "#066CFE",
  textAlign: "center",
  maxWidth: "195px",
  width: "100%",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));
export const OurTeamCardTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "1rem",
  //   marginBottom: "13px",
  color: COLORS.WHITE,
}));
export const OurTeamCardDesc = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "14px",
  color: COLORS.WHITE,
}));
