import React, { useState } from "react";
import axios from "axios";

import {
  Alert,
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { HeroLink } from "components/Hero/hero.styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { COLORS } from "styles/theme";
import {
  ContactContent,
  ContactInput,
  ContactTextArea,
  ContactTitle,
  ContactUSContainer,
} from "./contactUs.style";

const contactUsContent = {
  title: "Get In Touch!",
  desc: "If you have any questions or concerns, our consultants are willing to help. Please contact us and a consultant will reach out within 2 business days. We look forward to assisting you.",
  btn: "send",
};
function Form() {
  const [mouseHover, setMouseHover] = useState(0);
  const [formErrors, setFormError] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  const initialFormState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  };
  const [formData, setFormData] = useState(initialFormState);
  const { firstName, lastName, email, phoneNumber, message } = formData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShowSuccess(false);
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_ZOHO_BACKEND}/lead/create`;

    try {
      const requestBody = {
        ...formData,
      };
      await axios.post(url, requestBody);
      setShowSuccess(true);
      setFormError([]);
      setFormData(initialFormState);
    } catch (error) {
      setFormError([...error.response.data.message]);
    }
  };

  return (
    <Stack
      component="form"
      justifyContent="space-between"
      sx={{
        width: "100%",
        maxWidth: 500,
        height: "100%",
        zIndex: 100,
        gap: 2,
        margin: { xs: "auto", md: 0 },
      }}
      onSubmit={handleSubmit}
    >
      {formErrors.length > 0 && (
        <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
          {formErrors.map((error) => (
            <Typography component="li" key="error">
              {error}
            </Typography>
          ))}
        </Alert>
      )}
      {showSuccess && (
        <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
          <Typography key="error">Message Was sent successfully</Typography>
        </Alert>
      )}
      <Stack direction="row" justifyContent="space-between">
        <Stack width="48%">
          <Typography
            sx={{ color: COLORS.WHITE, fontWeight: 400 }}
            component="label"
            htmlFor="firstName"
          >
            First Name
          </Typography>
          <ContactInput
            placeholder="Enter your first Name"
            required
            id="firstName"
            name="firstName"
            value={firstName}
            onChange={handleInputChange}
          />
        </Stack>
        <Stack width="48%">
          <Typography
            sx={{ color: COLORS.WHITE, fontWeight: 400 }}
            component="label"
            htmlFor="lastName"
          >
            Last Name
          </Typography>
          <ContactInput
            placeholder="Enter your last Name"
            required
            id="lastName"
            name="lastName"
            value={lastName}
            onChange={handleInputChange}
          />
        </Stack>
      </Stack>

      <Stack>
        <Typography
          sx={{ color: COLORS.WHITE, fontWeight: 400 }}
          component="label"
          htmlFor="email"
        >
          Email
        </Typography>
        <ContactInput
          required
          placeholder="Anyone@mail.com"
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={handleInputChange}
        />
      </Stack>
      <Stack>
        <Typography
          sx={{ color: COLORS.WHITE, fontWeight: 400 }}
          component="label"
          htmlFor="phoneNumber"
        >
          Phone Number
        </Typography>
        <ContactInput
          required
          placeholder="+20123456789"
          id="phoneNumber"
          name="phoneNumber"
          value={phoneNumber}
          onChange={handleInputChange}
        />
      </Stack>

      <Stack>
        <Typography
          sx={{ color: COLORS.WHITE, fontWeight: 400 }}
          component="label"
          htmlFor="message"
        >
          Message
        </Typography>
        <ContactTextArea
          placeholder="Leave your message here"
          id="message"
          name="message"
          rows="5"
          value={message}
          onChange={handleInputChange}
        />
      </Stack>

      <Stack
        mt={2}
        direction="row"
        justifyContent="end"
        onMouseEnter={() => setMouseHover(1)}
        onMouseLeave={() => setMouseHover(0)}
      >
        <HeroLink type="submit" isHovered={mouseHover}>
          {contactUsContent.btn}
          <Box
            sx={{
              pb: "4px",
              transition: "all .2s",
              position: "absolute",
              right: mouseHover ? "5px" : "15px",
              top: "12px",
            }}
          >
            <ArrowForwardIosIcon />
          </Box>
        </HeroLink>
      </Stack>
    </Stack>
  );
}

function ContactUs() {
  return (
    <ContactUSContainer>
      <Container sx={{ height: "100%" }}>
        <Grid
          container
          rowSpacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={6}>
            <ContactTitle variant="h3">{contactUsContent.title}</ContactTitle>
            <ContactContent>{contactUsContent.desc}</ContactContent>
          </Grid>
          <Grid item xs={12} md={6} display="flex" justifyContent="center">
            <Form />
          </Grid>
        </Grid>
      </Container>
    </ContactUSContainer>
  );
}

export default ContactUs;
