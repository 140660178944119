import { Outlet } from "react-router-dom";
import Footer from "components/Footer/footer.component";
import Navbar from "components/Navbar/navbar.component";

function Navigation() {
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
}

export default Navigation;
