import { styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import { COLORS } from "styles/theme";

export const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: "2.5rem",
  fontFamily: "Tomato Grotesk",
  fontWeight: 900,
  lineHeight: "48px",
  position: "relative",
  textAlign: "center",
  zIndex: 676,
  marginBottom: theme.spacing(4),
  "&::after": {
    content: '""',
    width: "61px",
    height: "11px",
    backgroundColor: COLORS.PRIMARY,
    position: "absolute",
    left: 0,
    bottom: "2%",
    zIndex: -1,
  },
  [theme.breakpoints.down("sm")]: {
    width: 280,
    maxWidth: "fit-content",
  },
}));

export const SectionSubTitle = styled(Typography)(() => ({
  fontSize: "18px",
  fontFamily: "Montserrat",
  fontWeight: 600,
  color: COLORS.TEXT_GREY,
  textAlign: "center",
}));
