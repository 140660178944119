import { Box, Typography, styled } from "@mui/material";

export const WhyJoinTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "1.5rem",
  color: "#066CFE",
}));
export const WhyJoinDesc = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
}));
export const WhyJoinBackImg = styled("img")(({ theme }) => ({
  position: "absolute",
  top: "18%",
  //   top: { xs: "-1%", md: "23%" },
  left: "-2%",
  zIndex: -1,
}));
