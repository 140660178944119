import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import { COLORS } from "styles/theme";
import SECTIONS_CONTENT from "shared/sections";
import {
  BenefitsCard,
  BenefitsCardDesc,
  BenefitsCardImg,
  BenefitsCardTitle,
  BenefitsCardsContent,
  BenefitsWrapper,
} from "./Benefits.style";

function Benefits() {
  const { BENEFITS } = SECTIONS_CONTENT;
  const cards = BENEFITS?.cards?.map((card) => (
    <Grid item md={4} key={card.title}>
      <BenefitsCard>
        <BenefitsCardImg src={card.img.src} />
        <BenefitsCardTitle variant="body1">{card.title}</BenefitsCardTitle>
        <BenefitsCardDesc variant="body1">{card.desc}</BenefitsCardDesc>
      </BenefitsCard>
    </Grid>
  ));
  return (
    <BenefitsWrapper>
      <Container>
        <Typography
          variant="h3"
          textAlign="center"
          sx={{ marginBottom: 1, color: "#404040" }}
        >
          {BENEFITS.title}
        </Typography>
        <Typography
          variant="body1"
          textAlign="center"
          sx={{
            color: COLORS.TEXT_GREY,
            fontWeight: 400,
            width: "85%",
            margin: "auto",
            marginBottom: 8,
          }}
        >
          {BENEFITS.subTitle}
        </Typography>
        <BenefitsCardsContent>
          <Grid container justifyContent="center" spacing={8}>
            {cards}
          </Grid>
        </BenefitsCardsContent>
      </Container>
    </BenefitsWrapper>
  );
}

export default Benefits;
