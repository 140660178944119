import { Box, styled } from "@mui/material";
import { COLORS } from "styles/theme";

export const TermsContainer = styled(Box)(({ theme }) => ({
  minHeight: "615px",
  backgroundColor: COLORS.BLACK,
  paddingTop: theme.spacing(16),
  marginTop: theme.spacing(-8),
}));

export default TermsContainer;
