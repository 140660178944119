import { useState } from "react";

import SECTIONS_CONTENT from "shared/sections";

import { Box } from "@mui/material";
import Hero from "./hero.components";
import Vacancies from "./vacancies/vacancies.component";
import JobForm from "./jobForm.component";
import WhyJoin from "./WhyJoin/WhyJoin.component";
import JoinSV from "./JoinSV/JoinSV.component";
import Benefits from "./Benefits/Benefits.component";
import OurTeam from "./OurTeam/OurTeam.component";

function CareersContent() {
  const { CAREERS_CONTENT } = SECTIONS_CONTENT;
  const [vacancies, setVacancies] = useState(CAREERS_CONTENT.vacancies);

  return (
    <>
      {/* Hero Section */}
      {/* <Hero setVacancies={setVacancies} /> */}
      <Box
        sx={{
          backgroundColor: "#404040",
          height: "102px",
          width: "100%",
          position: "absolute",
        }}
      />
      <JoinSV />
      {/* Team Members Section */}
      <WhyJoin />
      <Benefits />
      {/* <OurTeam /> */}
      {/* Vacancies Section */}
      <Vacancies vacancies={vacancies} />

      {/* Apply for a Job Form Section */}
      {/* <JobForm /> */}
    </>
  );
}

export default CareersContent;
