// MUI
import { Card, Stack, styled, Typography, Link } from "@mui/material";
import Container from "@mui/material/Container";
// theme
import { COLORS } from "styles/theme";

export const CardBox = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: "368px",
  height: "100%",
  boxShadow: "none",

  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
  },
}));

export const CardContentBox = styled(Stack)(() => ({
  padding: "16px",
  height: "100%",
}));
export const CardContentDetails = styled(Typography)(() => ({
  overflow: "hidden",
  fontWeight: 400,
  marginBottom: "16px",
  color: COLORS.TEXT_GREY,
  display: "-webkit-box",
  WebkitLineClamp: 4,
  WebkitBoxOrient: "vertical",
  maxHeight: 96,
}));

export const CardImage = styled("img")(({ theme }) => ({
  height: "100%",
  width: "100%",
  maxWidth: 500,
  objectFit: "contain",
}));

export const CardTitle = styled(Typography)(() => ({
  fontFamily: "Tomato Grotesk",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "18px",
  marginBottom: "8px",
  color: COLORS.TEXT_BLACK,
}));
export const BlogSubtitle = styled(Typography)(() => ({
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "28px",
  maxWidth: "1073px",
  textAlign: "center",
  color: COLORS.TEXT_GREY,
}));

export const CardButton = styled(Link)(() => ({
  textAlign: "center",
  borderRadius: "100px",
  minWidth: "3.125rem",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "18px",
  marginTop: "auto",
}));
