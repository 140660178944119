import { createTheme } from "@mui/material";

export const COLORS = {
  PRIMARY: "#056BFF",
  SECONDARY: "#174380",
  BLACK: "#121212",
  BLACK_SECONDARY: "#1E1E1E",
  BACKGROUND: "#FAFAFA",
  GREEN: "#0AC70F",
  GREY_LIGHT: "#E7E3E3",
  GREY_DARK: "#A7A7A7",
  TEXT_GREY: "#585858",
  TEXT_GREY_LIGHT: "#797979",
  BLOG_TITLE: "#130F26",
  WHITE: "#FFFFFF",
  CAREERS_SEARCH_BG: "rgba(117, 117, 117, 0.58);",
};

export const SHADOWS = {
  CTA: "0px 4px 4px rgba(157, 157, 157, 0.75)",
  CARDS: "drop-shadow(0px 14px 30px rgba(157, 157, 157, 0.15))", // filter property
  SECTOR_CARD: "10px 9px 33px rgba(184, 184, 184, 0.25)",
  CAREERS_SEARCH: "20px 20px 120px 15px rgba(0, 0, 0, 0.05);",
};

export const GRADIENTS = {
  HERO_TITLE: `linear-gradient(179.89deg, rgba(255, 255, 255, 0) -32.33%, ${COLORS.WHITE} 99.9%)`,
  VERTICAL_BAR: `linear-gradient(180deg, ${COLORS.PRIMARY} 0%, rgba(23, 67, 128, 0) 100%)`,
  FOOTER_BG: `linear-gradient(180deg, #141414 0%,${COLORS.PRIMARY} 100%)`,
};
const theme = createTheme();

const customTheme = createTheme({
  palette: {
    background: {
      default: COLORS.BACKGROUND,
    },
    primary: {
      main: COLORS.PRIMARY,
    },
    secondary: {
      main: COLORS.SECONDARY,
    },
  },
  typography: {
    fontFamily: "Montserrat, sans-serif",
    h1: {
      fontFamily: "Tomato Grotesk",
      fontWeight: 900,
      [theme.breakpoints.down("md")]: {
        fontSize: "4rem",
      },
    },
    h2: {
      fontFamily: "Tomato Grotesk",
      fontWeight: 900,
      fontSize: "3rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "2.5rem",
      },
    },
    h3: {
      fontFamily: "Tomato Grotesk",
      fontWeight: 700,
      fontSize: "2.5rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.5rem",
      },
    },
    h5: {
      fontFamily: "Tomato Grotesk",
      fontWeight: 700,
      [theme.breakpoints.down("md")]: {
        fontSize: "1.2rem",
      },
    },
    subtitle1: {
      fontFamily: "Tomato Grotesk",
      fontWeight: 500,
      fontSize: "1.25rem",
      color: "#000",
      [theme.breakpoints.down("md")]: {
        fontSize: "1rem",
      },
    },
    body1: {
      fontWeight: 500,
      [theme.breakpoints.down("md")]: {
        fontSize: "1rem",
      },
    },
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 700,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#6b6b6b #2b2b2b",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#2b2b2b",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#6b6b6b",
            minHeight: 24,
            border: "3px solid #2b2b2b",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#2b2b2b",
          },
        },
      },
    },
  },
});

export default customTheme;
