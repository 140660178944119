import { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import IMAGES from "shared/images";
import StyledLink from "./link.styles";

// TODO: Make it Responsive
function Link({ href, text, containerStyles, linkStyles }) {
  const [mouseHover, setMouseHover] = useState(0);

  return (
    <Box
      sx={[
        {
          pt: 8,
          display: "flex",
          alignItems: "flex-end",
          gap: 2,
        },
        containerStyles,
      ]}
      onMouseEnter={() => setMouseHover(1)}
      onMouseLeave={() => setMouseHover(0)}
    >
      <StyledLink href={href} isHovered={mouseHover} sx={linkStyles}>
        {text}
      </StyledLink>
      <Box
        component="img"
        src={IMAGES.RIGHT_ARROW_GREEN}
        sx={{
          pb: "4px",
          transition: "margin .5s",
          marginRight: mouseHover ? "-10px" : "-25px",
          zIndex: 100,
        }}
      />
      <Box
        component="img"
        src={IMAGES.RIGHT_ARROW_GREEN}
        sx={{
          pb: "4px",
          zIndex: 100,
        }}
      />
    </Box>
  );
}

Link.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  linkStyles: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  containerStyles: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
};

Link.defaultProps = {
  linkStyles: {},
  containerStyles: {},
};

export default Link;
