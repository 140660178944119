const { styled, Tabs, Tab, Box, Typography } = require("@mui/material");

export const StyledTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#066CFE",
  },
});
export const StyledTab = styled(Tab)(({ theme }) => ({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "24px",
  letterSpacing: "0.4px",
  textTransform: "uppercase",
  color: "rgba(0, 0, 0, 0.6)",
  "&.Mui-selected": {
    color: "#066CFE",
    fontWeight: 700,
  },
}));
export const SectorContainerImg = styled(Box)(({ theme }) => ({
  maxWidth: "430px",
  maxHeight: "350px",
  [theme.breakpoints.down("md")]: {
    maxWidth: "330px",
    maxHeight: "250px",
    paddingTop: "20px",
  },
}));
export const SectorImg = styled("img")({
  height: "100%",
  width: "100%",
});
export const SectorContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#000",
  height: "478px",
  position: "relative",
  zIndex: -2,
  [theme.breakpoints.down("md")]: {
    height: "fit-content",
  },
}));
export const SectorSide = styled(Box)(({ theme }) => ({
  backgroundColor: "#066CFE",
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  width: "20%",
  zIndex: -1,
  [theme.breakpoints.down("md")]: {
    // height: "70%",
    minWidth: "176px",
    maxHeight: "300px",
  },
}));
export const SectorContentContainer = styled(Box)(({ theme }) => ({
  paddingRight: "40px",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "20px",
  },
}));
export const SectorContentTitle = styled(Typography)({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "29px",
  color: "#fff",
  marginBottom: "32px",
});
export const SectorContentContent = styled(Typography)({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "40px",
  color: "#D6D6D6",
});
